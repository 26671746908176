<div class="row fixed-bottom" style="
background: #00897B;
padding: 16px 0px 16px 0px;
font-size: 16px;
letter-spacing: 1px;
">
    <div class="col-6 tabcolor" style="text-align: center;" [ngClass]="{activetabfooter:home}"  [routerLink]="['/wallet-home']"><i  class="fa fa-home"></i> Home</div>
    <div class="col-6 tabcolor" style="text-align: center;" [ngClass]="{activetabfooter:passbook}"  [routerLink]="['/passbook']"><i class="fa fa-newspaper" [routerLink]="['/passbook']"></i> Passbook</div>
    <!-- <div class="col-4 tabcolor" style="text-align: center;" [ngClass]="{activetabfooter:id}"  [routerLink]="['/ids']"><i  class="fa fa-user"></i> IDs</div> -->
</div>
