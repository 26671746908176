export const bankData = {
  "message": "success",
  "code": 0,
  "error": false,
  "data": [
  {
    "id": 54,
    "name": "Abhyudaya Co-Operative Bank Ltd",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/abhyudaya.jpeg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 1,
    "name": "Allahabad Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/allahabad_bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 2,
    "name": "Andhra Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Andhra_Bank.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 55,
    "name": "AU Small Finance Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/au_small_finance_bank.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 3,
    "name": "Axis Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Axis_Bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 43,
    "name": "Bandhan Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/bandhan_bank.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 4,
    "name": "Bank of Bahrain and Kuwait",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Bank_of_Bahrain_and_Kuwait.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 5,
    "name": "Bank of Baroda",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Bank_of_Baroda.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 6,
    "name": "Bank of India",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Bank_of_India.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 7,
    "name": "Bank of Maharashtra",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Bank_of_Maharashtra.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 8,
    "name": "Canara Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Canara_Bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 44,
    "name": "Catholic Syrian Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/csb_bank.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 9,
    "name": "Central Bank of India",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Central_Bank_of_India.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 45,
    "name": "Citibank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/citi_bank.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 10,
    "name": "City Union Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/city_union_bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 11,
    "name": "Corporation Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Corporation_Bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 46,
    "name": "DCB Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/dcb_bank.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 47,
    "name": "Dhanlaxmi Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/dhanlaxmi_bank.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 56,
    "name": "Equitas Small Finance Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/equitas_small_finance_bank.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 12,
    "name": "Federal Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Federal_Bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 42,
    "name": "HDFC Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/hdfc_bank.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 13,
    "name": "ICICI Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/icici_bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 14,
    "name": "IDBI Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/IDBI_Bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 48,
    "name": "IDFC FIRST Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/idfc_first_bank.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 49,
    "name": "Indiabulls",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/indiabulls.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 15,
    "name": "Indian Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Indian-Bank-Logo.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 16,
    "name": "Indian Overseas Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Indian_Overseas_Bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 17,
    "name": "IndusInd Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Indusind-Bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 41,
    "name": "ING Vysya Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/ing.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 18,
    "name": "Jammu and Kashmir Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Jammu_and_Kashmir_Bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 19,
    "name": "Karnataka Bank Ltd",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Karnataka_Bank_Ltd.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 40,
    "name": "Karur Vysya Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/kvb.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 20,
    "name": "Kotak Mahindra Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Kotak_Mahindra_Bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 21,
    "name": "Lakshmi Vilas Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Laxmi_Vilas_Bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 50,
    "name": "Nainital Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/nainital_bank.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 22,
    "name": "Oriental Bank of Commerce",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Oriental_Bank_of_Commerce.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 53,
    "name": "Paytm Payments Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/paytm_payments_bank.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 24,
    "name": "Punjab & Sind Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Punjab-Sind-bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 23,
    "name": "Punjab National Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/punjab-national-bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 51,
    "name": "RBL Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/rbl_bank.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 25,
    "name": "Shamrao Vitthal Co-operative Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Shamrao Vitthal_Co-operative_Bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 26,
    "name": "South Indian Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/South_Indian_Bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 52,
    "name": "Standard Chartered",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/standard_chartered.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 27,
    "name": "State Bank of Bikaner & Jaipur",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/state Bank_of_Bikaner_&_Jaipur.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 28,
    "name": "State Bank of Hyderabad",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/State_Bank_of_Hyderabad.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 29,
    "name": "State Bank of India",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/State_Bank_of_Hyderabad.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 30,
    "name": "State Bank of Mysore",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/State_Bank_of_Mysore.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 31,
    "name": "State Bank of Patiala",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/State_Bank_of_Hyderabad.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 32,
    "name": "State Bank of Travancore",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/State_Bank_of_Travancore.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 33,
    "name": "Syndicate Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Syndicate_Bank.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 34,
    "name": "Tamilnad Mercantile Bank Ltd.",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Tamilnad_Mercantile_Bank Ltd.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 35,
    "name": "UCO Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/UCO_Bank.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 36,
    "name": "Union Bank of India",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/Union_Bank_of_India.png",
    "status": 1,
    "random_string": null
  },
  {
    "id": 37,
    "name": "United Bank of India",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/United_Bank_of India.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 38,
    "name": "Vijaya Bank",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/vijay_bank.jpg",
    "status": 1,
    "random_string": null
  },
  {
    "id": 39,
    "name": "Yes Bank Ltd",
    "icon": "https://dbk6o2vuedjx3.cloudfront.net/go-punt//uploads/bank/yes_bank.jpg",
    "status": 1,
    "random_string": null
  }

]
}