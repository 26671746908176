<!-- url(/assets/background/sports.jpg) -->
<div class="loginPage"  style="background-color: #1689c2;">
    <div class="login-main">
        <div class="login-inner">           
            <div class="logo text-center">
                <img src="assets/logo/{{logo}}.png" alt="img">
            </div>
            <!-- register -->
            <div *ngIf="step===1" class="form">
                <form class="login-form" [formGroup]='loginForm' (ngSubmit) ='onLoginClick()'>
            
                    <div class="form-group" appearance="fill">
                        <label class="form-label">Mobile No.</label>
                        <input type="text" class="form-control" formControlName='phone'>
                        <mat-icon matSuffix>mobile_friendly</mat-icon>
                    </div>

                    <div class="submit-btn">
                        <button mat-raised-button color="primary" [disabled]="loginButtonDisable"><span style="font-size:17px;">LOGIN NOW </span><mat-icon matSuffix class="material-icons">login</mat-icon></button>
                    </div>
                    
                </form>
                
            </div>
             <!-- verifyOtp -->  
             <div *ngIf="step===2" class="form">
                <form class="login-form"  [formGroup]= 'varifyOtpForm' (ngSubmit) ='onVerifyOtpClick()'>
                    
                    <div class="form-group" appearance="fill">
                        <label class="form-label">Mobile No.</label>
                        <input type="text" class="form-control" formControlName='phone' disabled>
                        <mat-icon matSuffix>mobile_friendly</mat-icon>
                        </div>

                    <div class="form-group" appearance="fill">
                        <label class="form-label">OTP</label>
                        <input [type]="showPassword ? 'text' : 'password'" class="form-control" formControlName='otp'>
                        <mat-icon style="cursor:pointer;" matSuffix [class.hide]="showPassword" (click)="showPassword = !showPassword">visibility</mat-icon>
                        <mat-icon style="cursor:pointer;" matSuffix [class.hide]="!showPassword" (click)="showPassword = !showPassword">visibility_off</mat-icon>
                    </div>

                    <div class="submit-btn">
                        <button mat-raised-button color="primary" [disabled]="otpButtonDisable"><span style="font-size:17px;">Verify OTP </span><mat-icon matSuffix class="material-icons">how_to_reg</mat-icon></button>
                    </div>
                    
                </form>
                
            </div> 

        </div>
        
    </div>
    
</div>