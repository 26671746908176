<header>
  <div class="header">
    <mat-toolbar class="mat-toolbar-row">
      <div class="left-col">
        <span (click)="inplay()"
          ><img
            src="assets/logo/{{ logo }}.png"
            [ngStyle]="
              logo == 'fairbets247'
                ? { 'max-width': '50px' }
                : { 'max-width': '100px' }
            "
            alt="{{ logo }}"
        /></span>
      </div>
      <span class="div-spacer"></span>
      <div class="right-col">
        <ng-container *ngIf="loginButtnStatus">
          <button mat-stroked-button class="balance">
            <mat-icon>account_balance_wallet</mat-icon> Ba: <br />
            <span> {{ walletBalance | number : "0.0-2" }}</span>
          </button>
          <button (click)="openModalmyBets(myBets)" mat-stroked-button>
            <mat-icon>exposure</mat-icon> Ex :<br />
            <span> {{ exposure | number : "0.0-2" }}</span>
          </button>
        </ng-container>
        <button
          *ngIf="!loginButtnStatus"
          mat-stroked-button
          [routerLink]="['/login']"
          color="accent"
        >
          <mat-icon>login</mat-icon> login<br />
        </button>
      </div>
    </mat-toolbar>
  </div>
</header>

<ng-template #myBets>
  <div class="modal-header">
    <h4
      class="modal-title header-bg"
      style="background-color: #0188cc !important; color: white"
    >
      MY Market <i class="material-icons">refresh</i>
    </h4>
    <a
      type="button"
      class="close"
      data-dismiss="modal"
      (click)="modalRef.hide()"
      ><i class="material-icons">close</i>
    </a>
  </div>
  <div class="modal-body bg-light matchpopbody">
    <div class="modelcontentexposer">
      <div class="table-div mt-0">
        <table class="table" width="100%">
          <thead class="thead-dark">
            <tr>
              <th>Event Type</th>
              <th>Event Name</th>
              <th>Match Name</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let x of markets">
              <td>
                <span>{{ x?.eventTypeName }}</span
                ><span *ngIf="x.marketType === 'Special'">Cricket</span>
              </td>
              <td (click)="matchDetail(x.eventId); modalRef.hide()">
                <span class="text-primary">{{ x.eventName }}</span>
              </td>
              <td>{{ x.marketType }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer macthpopfoot">
    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
    <button
      type="button"
      class="btn btn-danger"
      data-dismiss="modal"
      (click)="modalRef.hide()"
    >
      Close
    </button>
  </div>
</ng-template>
