<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <app-header></app-header>
        <div *ngIf="lodTimer" id="overlay" style="
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        ">
            <div style="font-size: 7rem" class="font-weight-bold text-warning">
                {{ lodTimer }}
            </div>
        </div>

        <!-- mainClass -->
        <div class="main-card">
            <!-- internetDisscont -->
            <div *ngIf="!internetConn" class="col-12" style="margin-top: 38vh">
                <h4 class="font-weight-bold text-center text-warning">
                    Please check your internet connection and try again
                </h4>
            </div>

            <!-- content -->

            <!--//////////////////Desktop Start////////////////////////-->
            <div *ngIf="deviceInfo && page_type === 'diamond'" class="desktopmain-main">
                <!-- <ng-container *ngIf="page_type === 'diamond'"> </ng-container> -->
                <!-- desktop_header -->
                <app-desk-header></app-desk-header>
                <!-- desktop_header_end -->
                <div class="container-fluid container-fluid-5">
                    <div class="row row5">
                        <div class="sidebar col-md-2">
                            <!-- desktop_sidebar_others  -->
                            <app-desk-sidebar></app-desk-sidebar>
                        </div>
                        <!---->
                        <div class="col-md-10 featured-box load game-page">
                            <div class="row row5">
                                <div class="col-md-9 featured-box-detail sports-wrapper m-b-10">
                                    <!---->
                                    <!---->
                                    <div class="game-heading">
                                        <span class="card-header-title">{{ matchName }}</span>
                                        <span class="float-right">{{
                                            matchDate | date : "MM/d/yy hh:mm aa"
                                            }}</span>
                                    </div>
                                    <div class="game-heading">
                                        <iframe id="fp_embed_player" [src]="graphicTvUrl" marginwidth="0"
                                            marginheight="0" frameborder="0" width="100%" height="100%" scrolling="no"
                                            allowfullscreen="allowfullscreen"></iframe>
                                    </div>
                                    <div class="markets">
                                        <!---->
                                        <!---->
                                        <!--matchodds-->
                                        <div *ngIf="matchoddMarket?.length > 0 && manualMatchStatus"
                                            class="main-market">
                                            <ng-container *ngFor="
                            let matchOddsData of matchoddMarket;
                            trackBy: itemTrackBy;
                            let mDTIndex = index
                          ">
                                                <div class="market-title mt-1">
                                                    MATCH_ODDS
                                                    <span class="float-right">
                                                        <span *ngIf="
                                  matchodds_max_limit_check === true;
                                  else matchodds_max_check
                                ">Min:{{
                                                            matchodds_min_limit | shortNumber
                                                            }}
                                                            Max:{{ matchodds_max_limit | shortNumber }}</span>
                                                        <ng-template #matchodds_max_check>
                                                            <span *ngIf="matchOddsData?.eventTypeId === '4'">Min:{{
                                                                userDetails?.setting[0]?.min_bet | shortNumber
                                                                }}
                                                                Max:{{
                                                                userDetails?.setting[0]?.max_bet | shortNumber
                                                                }}</span>
                                                            <span *ngIf="matchOddsData?.eventTypeId === '2'">Min:{{
                                                                userDetails?.setting[2]?.min_bet | shortNumber
                                                                }}
                                                                Max:{{
                                                                userDetails?.setting[2]?.max_bet | shortNumber
                                                                }}</span>
                                                            <span *ngIf="matchOddsData?.eventTypeId === '1'">Min:{{
                                                                userDetails?.setting[1]?.min_bet | shortNumber
                                                                }}
                                                                Max:{{
                                                                userDetails?.setting[1]?.max_bet | shortNumber
                                                                }}</span>
                                                        </ng-template>
                                                        <!-- info_rule -->
                                                        <a (click)="
                                  openModalCondition(match_rules, 'Match Rules')
                                " href="javascript:void(0)" class="game-rules-icon"><span><i
                                                                    class="fa fa-info-circle float-right ml-2"></i></span></a></span>
                                                </div>
                                                <div class="table-header">
                                                    <div class="float-left country-name box-4 min-max">
                                                        <b></b>
                                                    </div>
                                                    <div class="box-1 float-left"></div>
                                                    <div class="box-1 float-left"></div>
                                                    <div class="back box-1 float-left text-center">
                                                        <b>BACK</b>
                                                    </div>
                                                    <div class="lay box-1 float-left text-center">
                                                        <b>LAY</b>
                                                    </div>
                                                    <div class="box-1 float-left"></div>
                                                    <div class="box-1 float-left"></div>
                                                </div>
                                                <div data-title="OPEN" class="table-body">
                                                    <div data-title="ACTIVE" class="table-row" *ngFor="
                                let runner of matchOddsData?.marketBook?.runners;
                                let i = index
                              ">
                                                        <div class="float-left country-name box-4">
                                                            <span class="team-name"><b>{{
                                                                    searchRunner(
                                                                    matchOddsData?.runners,
                                                                    runner?.selectionId
                                                                    )
                                                                    }}</b></span>
                                                            <p>
                                                                <span class="float-left" [ngClass]="{
                                      red:
                                        calProLoss(
                                          matchOddsData.runners,
                                          matchOddsData.marketBook,
                                          i,
                                          matchOddsData
                                        ) < 0,
                                      green:
                                        calProLoss(
                                          matchOddsData.runners,
                                          matchOddsData.marketBook,
                                          i,
                                          matchOddsData
                                        ) >= 0
                                    }">{{
                                                                    calProLoss(
                                                                    matchOddsData?.runners,
                                                                    matchOddsData?.marketBook,
                                                                    i,
                                                                    matchOddsData
                                                                    ) | number : "1.0-0"
                                                                    }}</span>
                                                            </p>
                                                        </div>
                                                        <div class="box-1 back2 float-left back-2 text-center">
                                                            <span class="odd d-block">-</span>
                                                            <span class="d-block">-</span>
                                                        </div>
                                                        <div class="box-1 back1 float-left back-1 text-center">
                                                            <span class="odd d-block">-</span>
                                                            <span class="d-block">-</span>
                                                        </div>
                                                        <div class="box-1 float-left lock text-center" [ngClass]="
                                  runner?.availableToBack?.price ===
                                  runner?.availableToBack?.oprice
                                    ? 'back'
                                    : 'price_blinking'
                                " *ngIf="runner?.availableToBack" (click)="
                                  getOddsValue(
                                    runner?.availableToBack?.price,
                                    runner?.selectionId,
                                    matchOddsData?.marketId,
                                    matchOddsData?.marketName,
                                    matchOddsData?.eventName,
                                    1,
                                    searchRunner(
                                      matchOddsData?.runners,
                                      runner?.selectionId
                                    ),
                                    matchOddsData?.marketType,
                                    betPlace
                                  )
                                ">
                                                            <span class="odd d-block">{{
                                                                runner?.availableToBack != undefined
                                                                ? runner?.availableToBack?.price
                                                                : ""
                                                                }}</span>
                                                            <span class="d-block">{{
                                                                (runner?.availableToBack != undefined
                                                                ? runner?.availableToBack?.size
                                                                : ""
                                                                ) | shortNumber
                                                                }}</span>
                                                        </div>
                                                        <div class="box-1 float-left text-center" [ngClass]="
                                  runner?.availableToLay?.price ===
                                  runner?.availableToLay?.oprice
                                    ? 'lay'
                                    : 'price_blinking'
                                " *ngIf="runner?.availableToLay" (click)="
                                  getOddsValue(
                                    runner?.availableToLay?.price,
                                    runner?.selectionId,
                                    matchOddsData?.marketId,
                                    matchOddsData?.marketName,
                                    matchOddsData?.eventName,
                                    0,
                                    searchRunner(
                                      matchOddsData?.runners,
                                      runner?.selectionId
                                    ),
                                    matchOddsData?.marketType,
                                    betPlace
                                  )
                                ">
                                                            <span class="odd d-block">{{
                                                                runner?.availableToLay != undefined
                                                                ? runner?.availableToLay?.price
                                                                : ""
                                                                }}</span>
                                                            <span class="d-block">{{
                                                                (runner?.availableToLay != undefined
                                                                ? runner?.availableToLay?.size
                                                                : ""
                                                                ) | shortNumber
                                                                }}</span>
                                                        </div>
                                                        <div class="box-1 lay1 float-left text-center">
                                                            <span class="odd d-block">-</span>
                                                            <span class="d-block">-</span>
                                                        </div>
                                                        <div class="box-1 lay2 float-left text-center">
                                                            <span class="odd d-block">-</span>
                                                            <span class="d-block">-</span>
                                                        </div>

                                                        <div *ngIf="checkMatchOddStatus(matchOddsData)"
                                                            class="suspendmatchods">
                                                            <div class="suspend-status">SUSPENDED</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>

                                        <!--TowinToss-->
                                        <div *ngIf="toWinTossMarket?.length > 0" class="main-market">
                                            <ng-container *ngFor="
                            let matchOddsData of toWinTossMarket;
                            trackBy: itemTrackBy;
                            let mDTIndex = index
                          ">
                                                <div class="market-title mt-1">
                                                    TO_WIN_TOSS
                                                    <span class="float-right">
                                                        <span *ngIf="
                                  matchodds_max_limit_check === true;
                                  else matchodds_max_check
                                ">Min:{{
                                                            matchodds_min_limit | shortNumber
                                                            }}
                                                            Max:{{ matchodds_max_limit | shortNumber }}</span>
                                                        <ng-template #matchodds_max_check>
                                                            <span *ngIf="matchOddsData?.eventTypeId === '4'">Min:{{
                                                                userDetails?.setting[0]?.min_bet | shortNumber
                                                                }}
                                                                Max:{{
                                                                userDetails?.setting[0]?.max_bet | shortNumber
                                                                }}</span>
                                                            <span *ngIf="matchOddsData?.eventTypeId === '2'">Min:{{
                                                                userDetails?.setting[2]?.min_bet | shortNumber
                                                                }}
                                                                Max:{{
                                                                userDetails?.setting[2]?.max_bet | shortNumber
                                                                }}</span>
                                                            <span *ngIf="matchOddsData?.eventTypeId === '1'">Min:{{
                                                                userDetails?.setting[1]?.min_bet | shortNumber
                                                                }}
                                                                Max:{{
                                                                userDetails?.setting[1]?.max_bet | shortNumber
                                                                }}</span>
                                                        </ng-template>
                                                        <!-- info_rule -->
                                                        <a (click)="
                                  openModalCondition(match_rules, 'Match Rules')
                                " href="javascript:void(0)" class="game-rules-icon"><span><i
                                                                    class="fa fa-info-circle float-right ml-2"></i></span></a></span>
                                                </div>
                                                <div class="table-header">
                                                    <div class="float-left country-name box-4 min-max">
                                                        <b></b>
                                                    </div>
                                                    <div class="box-1 float-left"></div>
                                                    <div class="box-1 float-left"></div>
                                                    <div class="back box-1 float-left text-center">
                                                        <b>BACK</b>
                                                    </div>
                                                    <div class="lay box-1 float-left text-center">
                                                        <b>LAY</b>
                                                    </div>
                                                    <div class="box-1 float-left"></div>
                                                    <div class="box-1 float-left"></div>
                                                </div>
                                                <div data-title="OPEN" class="table-body">
                                                    <div data-title="ACTIVE" class="table-row" *ngFor="
                                let runner of matchOddsData?.marketBook?.runners;
                                let i = index
                              ">
                                                        <div class="float-left country-name box-4">
                                                            <span class="team-name"><b>{{
                                                                    searchRunner(
                                                                    matchOddsData?.runners,
                                                                    runner?.selectionId
                                                                    )
                                                                    }}</b></span>
                                                            <p>
                                                                <span class="float-left" [ngClass]="{
                                      red:
                                        calProLoss(
                                          matchOddsData.runners,
                                          matchOddsData.marketBook,
                                          i,
                                          matchOddsData
                                        ) < 0,
                                      green:
                                        calProLoss(
                                          matchOddsData.runners,
                                          matchOddsData.marketBook,
                                          i,
                                          matchOddsData
                                        ) >= 0
                                    }">{{
                                                                    calProLoss(
                                                                    matchOddsData?.runners,
                                                                    matchOddsData?.marketBook,
                                                                    i,
                                                                    matchOddsData
                                                                    ) | number : "1.0-0"
                                                                    }}</span>
                                                            </p>
                                                        </div>
                                                        <div class="box-1 back2 float-left back-2 text-center">
                                                            <span class="odd d-block">-</span>
                                                            <span class="d-block">-</span>
                                                        </div>
                                                        <div class="box-1 back1 float-left back-1 text-center">
                                                            <span class="odd d-block">-</span>
                                                            <span class="d-block">-</span>
                                                        </div>
                                                        <div class="box-1 float-left lock text-center" [ngClass]="
                                  runner?.availableToBack?.price ===
                                  runner?.availableToBack?.oprice
                                    ? 'back'
                                    : 'price_blinking'
                                " *ngIf="runner?.availableToBack" (click)="
                                  getOddsValue(
                                    runner?.availableToBack?.price,
                                    runner?.selectionId,
                                    matchOddsData?.marketId,
                                    matchOddsData?.marketName,
                                    matchOddsData?.eventName,
                                    1,
                                    searchRunner(
                                      matchOddsData?.runners,
                                      runner?.selectionId
                                    ),
                                    matchOddsData?.marketType,
                                    betPlace
                                  )
                                ">
                                                            <span class="odd d-block">{{
                                                                runner?.availableToBack != undefined
                                                                ? runner?.availableToBack?.price
                                                                : ""
                                                                }}</span>
                                                            <span class="d-block">{{
                                                                (runner?.availableToBack != undefined
                                                                ? runner?.availableToBack?.size
                                                                : ""
                                                                ) | shortNumber
                                                                }}</span>
                                                        </div>
                                                        <div class="box-1 float-left text-center" [ngClass]="
                                  runner?.availableToLay?.price ===
                                  runner?.availableToLay?.oprice
                                    ? 'lay'
                                    : 'price_blinking'
                                " *ngIf="runner?.availableToLay" (click)="
                                  getOddsValue(
                                    runner?.availableToLay?.price,
                                    runner?.selectionId,
                                    matchOddsData?.marketId,
                                    matchOddsData?.marketName,
                                    matchOddsData?.eventName,
                                    0,
                                    searchRunner(
                                      matchOddsData?.runners,
                                      runner?.selectionId
                                    ),
                                    matchOddsData?.marketType,
                                    betPlace
                                  )
                                ">
                                                            <span class="odd d-block">{{
                                                                runner?.availableToLay != undefined
                                                                ? runner?.availableToLay?.price
                                                                : ""
                                                                }}</span>
                                                            <span class="d-block">{{
                                                                (runner?.availableToLay != undefined
                                                                ? runner?.availableToLay?.size
                                                                : ""
                                                                ) | shortNumber
                                                                }}</span>
                                                        </div>
                                                        <div class="box-1 lay1 float-left text-center">
                                                            <span class="odd d-block">-</span>
                                                            <span class="d-block">-</span>
                                                        </div>
                                                        <div class="box-1 lay2 float-left text-center">
                                                            <span class="odd d-block">-</span>
                                                            <span class="d-block">-</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>

                                        <!-- bookmaker -->
                                        <div *ngIf="bookmakerMarket?.length > 0" class="row row5 bookmaker-market mt-1">
                                            <div class="bm1 col-xl-12" *ngFor="
                            let matchOddsData of bookmakerMarket;
                            trackBy: itemTrackBy;
                            let mDTIndex = index
                          ">
                                                <div>
                                                    <div class="market-title mt-1">
                                                        Bookmaker market
                                                        <a href="javascript:void(0)" (click)="
                                  openModalCondition(
                                    match_rules,
                                    'Bookmaker Rules'
                                  )
                                " class="m-r-5 game-rules-icon"><span><i
                                                                    class="fa fa-info-circle float-right"></i></span></a>
                                                    </div>
                                                    <div class="table-header">
                                                        <div class="float-left country-name box-4 text-info">
                                                            <b *ngIf="matchOddsData.maxlimit; else bmax_min">Min:
                                                                <span>{{
                                                                    matchOddsData.minlimit | shortNumber
                                                                    }}</span>
                                                                Max:
                                                                <span>{{
                                                                    matchOddsData.maxlimit | shortNumber
                                                                    }}</span></b>

                                                            <ng-template #bmax_min>
                                                                <b *ngIf="
                                      bookmaker_max_limit_check === true;
                                      else bookmaker_max_check
                                    ">Min:
                                                                    <span>{{
                                                                        bookmaker_min_limit | shortNumber
                                                                        }}</span>
                                                                    Max:
                                                                    <span>{{
                                                                        bookmaker_max_limit | shortNumber
                                                                        }}</span>
                                                                </b>

                                                                <ng-template #bookmaker_max_check>
                                                                    <b>Min:
                                                                        <span>{{
                                                                            userDetails?.setting[0]?.min_bet
                                                                            | shortNumber
                                                                            }}</span>
                                                                        Max:
                                                                        <span>{{
                                                                            userDetails?.setting[0]?.max_bet
                                                                            | shortNumber
                                                                            }}</span>
                                                                    </b>
                                                                </ng-template>
                                                            </ng-template>
                                                        </div>

                                                        <div class="box-1 float-left"></div>
                                                        <div class="box-1 float-left"></div>
                                                        <div class="back box-1 float-left text-center">
                                                            <b>BACK</b>
                                                        </div>
                                                        <div class="lay box-1 float-left text-center">
                                                            <b>LAY</b>
                                                        </div>
                                                        <div class="box-1 float-left"></div>
                                                        <div class="box-1 float-left"></div>
                                                    </div>
                                                    <div class="table-body">
                                                        <div *ngFor="
                                  let runner of matchOddsData.marketBook.runners;
                                  let i = index
                                " data-title="ACTIVE" class="table-row bookmarkdesk">
                                                            <div class="float-left country-name box-4">
                                                                <span class="team-name"><b>{{
                                                                        searchRunner(
                                                                        matchOddsData?.runners,
                                                                        runner?.selectionId
                                                                        )
                                                                        }}</b></span>
                                                                <p>
                                                                    <span class="float-left" [ngClass]="{
                                        red:
                                          calProLoss(
                                            matchOddsData?.runners,
                                            matchOddsData?.marketBook,
                                            i,
                                            matchOddsData
                                          ) < 0,
                                        green:
                                          calProLoss(
                                            matchOddsData?.runners,
                                            matchOddsData?.marketBook,
                                            i,
                                            matchOddsData
                                          ) >= 0
                                      }">{{
                                                                        calProLoss(
                                                                        matchOddsData?.runners,
                                                                        matchOddsData?.marketBook,
                                                                        i,
                                                                        matchOddsData
                                                                        ) | number : "1.0-0"
                                                                        }}</span>
                                                                </p>
                                                            </div>
                                                            <div
                                                                class="box-1 back2 float-left text-center betting-disabled">
                                                                <!---->
                                                            </div>
                                                            <div
                                                                class="box-1 back1 float-left back-2 text-center betting-disabled">
                                                                <!---->
                                                            </div>
                                                            <div class="box-1 back float-left back lock text-center"
                                                                (click)="
                                    getOddsValue(
                                      runner?.availableToBack?.price,
                                      runner?.selectionId,
                                      matchOddsData?.marketId,
                                      matchOddsData?.marketName,
                                      matchOddsData?.eventName,
                                      1,
                                      searchRunner(
                                        matchOddsData?.runners,
                                        runner?.selectionId
                                      ),
                                      matchOddsData?.marketType,
                                      betPlace
                                    )
                                  ">
                                                                <span class="odd d-block"
                                                                    *ngIf="runner?.availableToBack">{{
                                                                    runner?.availableToBack != undefined
                                                                    ? runner?.availableToBack?.kprice
                                                                    : ""
                                                                    }}</span>
                                                                <span class="d-block">{{
                                                                    (runner?.availableToBack != undefined
                                                                    ? runner?.availableToBack?.size
                                                                    : ""
                                                                    ) | shortNumber
                                                                    }}</span>
                                                            </div>
                                                            <div class="box-1 lay float-left text-center" (click)="
                                    getOddsValue(
                                      runner?.availableToLay?.price,
                                      runner?.selectionId,
                                      matchOddsData?.marketId,
                                      matchOddsData?.marketName,
                                      matchOddsData?.eventName,
                                      0,
                                      searchRunner(
                                        matchOddsData?.runners,
                                        runner?.selectionId
                                      ),
                                      matchOddsData?.marketType,
                                      betPlace
                                    )
                                  ">
                                                                <span class="odd d-block"
                                                                    *ngIf="runner?.availableToLay">{{
                                                                    runner?.availableToLay != undefined
                                                                    ? runner?.availableToLay?.kprice
                                                                    : ""
                                                                    }}</span>
                                                                <span class="d-block">{{
                                                                    (runner?.availableToLay != undefined
                                                                    ? runner?.availableToLay?.size
                                                                    : ""
                                                                    ) | shortNumber
                                                                    }}</span>
                                                            </div>
                                                            <div
                                                                class="box-1 lay1 float-left text-center betting-disabled">
                                                                <!---->
                                                            </div>
                                                            <div
                                                                class="box-1 lay2 float-left text-center betting-disabled">
                                                                <!---->
                                                            </div>
                                                            <div class="suspendBookmaker" *ngIf="
                                    checkBookmakerStatus(matchOddsData, runner)
                                  ">
                                                                <div class="suspend-status">SUSPENDED</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="table-remark text-right remark">
                                                        {{ matchOddsData?.message }}
                                                    </div>
                                                    <div>
                                                        <!---->
                                                    </div>
                                                </div>
                                            </div>
                                            <!---->
                                        </div>
                                        <!---->
                                    </div>
                                    <ng-container *ngIf="sessionMarket?.length > 0">
                                        <div class="fancy-market row row5">
                                            <div class="col-6" *ngIf="fancy_click">
                                                <!-- session_market -->

                                                <div class="market-title mt-1">
                                                    Session Market
                                                    <a class="m-r-5 game-rules-icon" style="cursor: pointer" (click)="
                                openModalCondition(match_rules, 'Fancy Rules')
                              "><span><i class="fa fa-info-circle float-right"></i></span></a>
                                                </div>
                                                <div class="table-header">
                                                    <div class="float-left country-name box-6"></div>
                                                    <div class="box-1 float-left lay text-center">
                                                        <b>No</b>
                                                    </div>
                                                    <div class="back box-1 float-left back text-center">
                                                        <b>Yes</b>
                                                    </div>
                                                    <div class="box-2 float-left"></div>
                                                </div>
                                                <div class="table-body">
                                                    <div class="fancy-tripple">
                                                        <div class="desktopviewmarket" *ngFor="
                                  let runner of sessionMarket;
                                  trackBy: itemTrackBy;
                                  let mDTIndex = index
                                ">
                                                            <div data-title="" class="table-row">
                                                                <div class="float-left country-name box-6"
                                                                    style="border-bottom: 0px">
                                                                    <p class="m-b-0" (click)="
                                        session_book(
                                          sessionBook,
                                          runner?.marketId
                                        )
                                      ">
                                                                        <a>
                                                                            {{ runner?.marketName }}
                                                                        </a>
                                                                    </p>
                                                                    <p class="m-b-0" *ngIf="sessionMarketExposure">
                                                                        <span *ngIf="
                                          sessionMarketExposure[runner.marketId]
                                        " class="red">{{
                                                                            sessionMarketExposure[runner.marketId]
                                                                            }}</span>
                                                                    </p>
                                                                </div>
                                                                <div class="box-1 lay float-left text-center" (click)="
                                      setSessionValue(
                                        runner?.marketBook?.availableToLay?.price,
                                        runner?.marketId,
                                        runner?.marketName,
                                        runner?.eventName,
                                        0,
                                        runner?.marketBook?.availableToLay?.size,
                                        runner?.marketType,
                                        betPlace
                                      )
                                    ">
                                                                    <span class="odd d-block">{{
                                                                        runner?.marketBook?.availableToLay !=
                                                                        undefined
                                                                        ? runner?.marketBook?.availableToLay
                                                                        ?.price
                                                                        : ""
                                                                        }}</span>
                                                                    <span>{{
                                                                        (runner?.marketBook?.availableToLay !=
                                                                        undefined
                                                                        ? runner?.marketBook?.availableToLay?.size
                                                                        : ""
                                                                        ) | shortNumber
                                                                        }}</span>
                                                                </div>
                                                                <div class="box-1 back float-left text-center" (click)="
                                      setSessionValue(
                                        runner?.marketBook?.availableToBack
                                          ?.price,
                                        runner?.marketId,
                                        runner?.marketName,
                                        runner?.eventName,
                                        1,
                                        runner?.marketBook?.availableToBack?.size,
                                        runner?.marketType,
                                        betPlace
                                      )
                                    ">
                                                                    <span class="odd d-block">{{
                                                                        runner?.marketBook?.availableToBack !=
                                                                        undefined
                                                                        ? runner?.marketBook?.availableToBack
                                                                        ?.price
                                                                        : ""
                                                                        }}</span>
                                                                    <span>{{
                                                                        (runner?.marketBook?.availableToBack !=
                                                                        undefined
                                                                        ? runner?.marketBook?.availableToBack
                                                                        ?.size
                                                                        : ""
                                                                        ) | shortNumber
                                                                        }}</span>
                                                                </div>

                                                                <div class="box-2 float-left text-right min-max"
                                                                    style="border-bottom: 0px">
                                                                    <ng-container
                                                                        *ngIf="runner.maxlimit; else nfancy_assites">
                                                                        <span class="d-block">Min:
                                                                            <span>{{
                                                                                runner.minlimit | shortNumber
                                                                                }}</span></span>
                                                                        <span class="d-block">Max:
                                                                            <span>{{
                                                                                runner.maxlimit | shortNumber
                                                                                }}</span></span>
                                                                    </ng-container>
                                                                    <ng-template #nfancy_assites>
                                                                        <ng-container *ngIf="
                                          fancy_max_limit_check === true;
                                          else normal_session
                                        ">
                                                                            <span class="d-block">Min:
                                                                                <span>{{
                                                                                    fancy_min_limit | shortNumber
                                                                                    }}</span></span>
                                                                            <span class="d-block">Max:
                                                                                <span>{{
                                                                                    fancy_max_limit | shortNumber
                                                                                    }}</span></span>
                                                                        </ng-container>
                                                                        <ng-template #normal_session>
                                                                            <span class="d-block">Min:
                                                                                <span>{{
                                                                                    userDetails?.setting[0]?.min_bet
                                                                                    | shortNumber
                                                                                    }}</span></span>
                                                                            <span class="d-block">Max:
                                                                                <span>{{
                                                                                    userDetails?.setting[0]?.max_bet
                                                                                    | shortNumber
                                                                                    }}</span></span>
                                                                        </ng-template>
                                                                    </ng-template>
                                                                </div>
                                                                <div class="suspendBookmaker" *ngIf="
                                      runner?.marketBook?.statusLabel ==
                                      'Ball Running'
                                    ">
                                                                    <div class="suspend-status">Ball runnig</div>
                                                                </div>
                                                                <div class="suspendBookmaker" *ngIf="
                                      runner?.marketBook?.statusLabel ==
                                      'SUSPENDED'
                                    ">
                                                                    <div class="suspend-status">SUSPENDED</div>
                                                                </div>
                                                            </div>
                                                            <p *ngIf="runner.message" class="runmessagenew" style="
                                    color: #8c2110;
                                    padding: 0 4px;
                                    margin: 0px;
                                    text-align: right;
                                    font-weight: bold;
                                  ">
                                                                {{ runner.message }}
                                                            </p>
                                                        </div>
                                                        <!---->
                                                        <!---->
                                                    </div>
                                                </div>
                                                <!---->
                                                <div>
                                                    <!---->
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <!---->
                                                <!---->
                                            </div>
                                        </div>
                                        <ul class="nav nav-tabs mt-1">
                                            <!-- <li class="nav-item"><a data-toggle="tab" href="#fancy"
                                                  class="nav-link active">Fancy 1</a></li> -->
                                            <li class="nav-item">
                                                <a data-toggle="tab" href="#meter" class="nav-link">Meter</a>
                                            </li>
                                            <li class="nav-item">
                                                <a data-toggle="tab" href="#khado" class="nav-link">Khado</a>
                                            </li>
                                            <li class="nav-item">
                                                <a data-toggle="tab" href="#oddeven" class="nav-link">Odd Even</a>
                                            </li>
                                            <li class="nav-item">
                                                <a data-toggle="tab" href="#wicket" class="nav-link">Wicket</a>
                                            </li>
                                            <li class="nav-item">
                                                <a data-toggle="tab" href="#four" class="nav-link">Four</a>
                                            </li>
                                            <li class="nav-item">
                                                <a data-toggle="tab" href="#six" class="nav-link">Six</a>
                                            </li>
                                            <li class="nav-item">
                                                <a data-toggle="tab" href="#cc" class="nav-link">Cricket Casino</a>
                                            </li>
                                        </ul>
                                    </ng-container>
                                    <div class="tab-content fancy-tab">
                                        <!--Meter_market-->
                                        <div id="fancy" class="tab-pane active" *ngIf="meter_click">
                                            <div class="fancy-market row row5">
                                                <div class="col-6">
                                                    <div class="market-title mt-1">
                                                        <span>Meter Market</span>
                                                        <a (click)="
                                  openModalCondition(match_rules, 'Fancy Rules')
                                " href="javascript:void(0)" class="m-r-5 game-rules-icon"><span><i
                                                                    class="fa fa-info-circle float-right"></i></span></a>
                                                    </div>
                                                    <div class="table-header">
                                                        <div class="float-left country-name box-6"></div>
                                                        <div class="back box-1 float-left back text-center">
                                                            <b>BACK</b>
                                                        </div>
                                                        <div class="box-1 float-left lay text-center">
                                                            <b>LAY</b>
                                                        </div>
                                                        <div class="box-2 float-left"></div>
                                                    </div>
                                                    <div class="table-body">
                                                        <div data-title="" class="fancy-tripple">
                                                            <div class="table-row" *ngFor="
                                    let runner of meterMarket;
                                    trackBy: itemTrackBy;
                                    let mDTIndex = index
                                  ">
                                                                <div class="float-left country-name box-6"
                                                                    style="border-bottom: 0px">
                                                                    <p (click)="
                                        session_book(
                                          sessionBook,
                                          runner?.marketId
                                        )
                                      " class="m-b-0">
                                                                        <span>{{ runner?.marketName }}</span>
                                                                    </p>
                                                                    <p class="m-b-0" *ngIf="sessionMarketExposure">
                                                                        <span class="red" *ngIf="
                                          sessionMarketExposure[runner.marketId]
                                        ">{{
                                                                            sessionMarketExposure[runner.marketId]
                                                                            }}</span>
                                                                    </p>
                                                                </div>
                                                                <div class="box-1 back float-left text-center" (click)="
                                      setSessionValue(
                                        runner?.marketBook?.availableToLay?.price,
                                        runner?.marketId,
                                        runner?.marketName,
                                        runner?.eventName,
                                        0,
                                        runner?.marketBook?.availableToLay?.size,
                                        runner?.marketType,
                                        betPlace
                                      )
                                    ">
                                                                    <span class="odd d-block">{{
                                                                        runner?.marketBook?.availableToLay !=
                                                                        undefined
                                                                        ? runner?.marketBook?.availableToLay
                                                                        ?.price
                                                                        : ""
                                                                        }}</span>
                                                                    <span>{{
                                                                        (runner?.marketBook?.availableToLay !=
                                                                        undefined
                                                                        ? runner?.marketBook?.availableToLay?.size
                                                                        : ""
                                                                        ) | shortNumber
                                                                        }}</span>
                                                                </div>
                                                                <div class="box-1 lay float-left text-center lay"
                                                                    (click)="
                                      setSessionValue(
                                        runner?.marketBook?.availableToBack
                                          ?.price,
                                        runner?.marketId,
                                        runner?.marketName,
                                        runner?.eventName,
                                        1,
                                        runner?.marketBook?.availableToBack?.size,
                                        runner?.marketType,
                                        betPlace
                                      )
                                    ">
                                                                    <span class="odd d-block">{{
                                                                        runner?.marketBook?.availableToBack !=
                                                                        undefined
                                                                        ? runner?.marketBook?.availableToBack
                                                                        ?.price
                                                                        : ""
                                                                        }}</span>
                                                                    <span>{{
                                                                        (runner?.marketBook?.availableToBack !=
                                                                        undefined
                                                                        ? runner?.marketBook?.availableToBack
                                                                        ?.size
                                                                        : ""
                                                                        ) | shortNumber
                                                                        }}</span>
                                                                </div>

                                                                <!-- suspended -->
                                                                <div class="suspend" *ngIf="
                                      runner?.marketBook?.statusLabel ==
                                      'Ball Running'
                                    ">
                                                                    <div class="suspend-status">Ball runnig</div>
                                                                </div>
                                                                <div class="suspend" *ngIf="
                                      runner?.marketBook?.statusLabel ==
                                      'SUSPENDED'
                                    ">
                                                                    <div class="suspend-status">SUSPENDED</div>
                                                                </div>

                                                                <div class="box-2 float-left text-right min-max"
                                                                    style="border-bottom: 0px">
                                                                    <ng-container *ngIf="
                                        runner.maxlimit;
                                        else mnfancy_assites
                                      ">
                                                                        <span class="d-block">
                                                                            Min:
                                                                            <span>{{
                                                                                runner.minlimit | shortNumber
                                                                                }}</span></span>
                                                                        <span class="d-block">
                                                                            Max:
                                                                            <span>{{
                                                                                runner.maxlimit | shortNumber
                                                                                }}</span></span>
                                                                    </ng-container>
                                                                    <ng-template #mnfancy_assites>
                                                                        <ng-container *ngIf="
                                          fancy_max_limit_check === true;
                                          else normal_session
                                        ">
                                                                            <span class="d-block">
                                                                                Min:
                                                                                <span>{{
                                                                                    fancy_min_limit | shortNumber
                                                                                    }}</span></span>
                                                                            <span class="d-block">
                                                                                Max:
                                                                                <span>{{
                                                                                    fancy_max_limit | shortNumber
                                                                                    }}</span></span>
                                                                        </ng-container>
                                                                        <ng-template #normal_session>
                                                                            <span class="d-block">
                                                                                Min:
                                                                                <span>{{
                                                                                    userDetails?.setting[0]?.min_bet
                                                                                    | shortNumber
                                                                                    }}</span></span>
                                                                            <span class="d-block">
                                                                                Max:
                                                                                <span>{{
                                                                                    userDetails?.setting[0]?.max_bet
                                                                                    | shortNumber
                                                                                    }}</span></span>
                                                                        </ng-template>
                                                                    </ng-template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!---->
                                                    </div>
                                                    <p *ngIf="runner.message" class="runmessagenew" style="
                                color: #8c2110;
                                padding: 0 4px;
                                margin: 0px;
                                text-align: right;
                              ">
                                                        {{ runner.message }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <!---->
                                        <!---->
                                        <!---->
                                        <!---->
                                        <!---->
                                        <!---->
                                        <!---->
                                    </div>
                                </div>

                                <!--Desktop bet_show_section -->
                                <div id="sidebar-right" class="col-md-3 sidebar-right" style="position: relative">
                                    <div class="ps">
                                        <div class="sidebar-right-inner">
                                            <!--live_tv-->
                                            <div class="card m-b-10 place-bet">
                                                <div class="card-header">
                                                    <h6 class="card-title d-inline-block">Live Match</h6>
                                                    <span style="float: right; font-size: 14px" (click)="openTvDiv()">
                                                        <i class="fa fa-television text-white" aria-hidden="true"></i>
                                                        live stream started
                                                    </span>
                                                    <iframe id="fp_embed_player" [src]="liveUrl" marginwidth="0"
                                                        marginheight="0" frameborder="0" width="100%" height="240px"
                                                        scrolling="no" allowfullscreen="allowfullscreen"></iframe>
                                                </div>

                                                <!-- <div *ngIf="liveUrl" style="height: 240px" class="modal-content custom-modal-main" [ngClass]="
                              liveTVStatus ? 'liveTV-block' : 'liveTV-none'
                            ">
                            <iframe id="fp_embed_player" [src]="liveUrl" marginwidth="0" marginheight="0" frameborder="0"
                              width="100%" height="240px" scrolling="no" allowfullscreen="allowfullscreen"></iframe>
                          </div> -->
                                                <!---->
                                            </div>

                                            <!--place bet-->
                                            <div class="card m-b-10 place-bet">
                                                <div class="card-header">
                                                    <h6 class="card-title d-inline-block">Place Bet</h6>
                                                </div>

                                                <div *ngIf="Place_bet_sec" class="newmatchpopup"
                                                    [ngClass]="arrayObj.is_back == 1 ? 'back' : 'lay'">
                                                    <div class="newplacebet-sec">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>(Bet For)</th>
                                                                    <th>Odds</th>
                                                                    <th>Stake</th>
                                                                    <th>Profit</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <!-- bet for -->
                                                                    <td>
                                                                        <span *ngIf="
                                          arrayObj.is_fancy == 0;
                                          else fancy_name
                                        "><span class="font-weight-bold text-danger"
                                                                                style="font-size: 15px; cursor: pointer"
                                                                                (click)="Place_bet_sec = false">X</span>
                                                                            {{
                                                                            odds_bookmaker_db.bet.selectionName
                                                                            }}</span><ng-template #fancy_name>{{
                                                                            session_db?.bet?.marketName
                                                                            }}</ng-template>
                                                                    </td>
                                                                    <td>
                                                                        <div class="input-group">
                                                                            <div class="numbers">
                                                                                <span class="minus">-</span>
                                                                                <input type="text"
                                                                                    ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/"
                                                                                    min="0" step="0.01"
                                                                                    id="ShowBetPrice" name="quant[2]"
                                                                                    readonly=""
                                                                                    class="calProfitLoss odds-input form-control CommanBtn"
                                                                                    [(ngModel)]="arrayObj.odds" />
                                                                                <span class="plus">+</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <!-- stack -->
                                                                    <td>
                                                                        <input type="number" min="0" id="stakeValue"
                                                                            pattern="[0-9]*" step="1" placeholder="0"
                                                                            class="form-control calProfitLoss stake-input f0orm-control CommanBtn"
                                                                            ng-reflect-pattern="[0-9]*"
                                                                            ng-reflect-model="0" (input)="
                                          calculateP_lOnStackOnInput(
                                            arrayObj.stake,
                                            arrayObj.stake,
                                            arrayObj.is_back,
                                            arrayObj
                                          )
                                        " [(ngModel)]="
                                          arrayObj.stake < 0
                                            ? '0'
                                            : arrayObj.stake
                                        " />
                                                                    </td>
                                                                    <!-- profit -->
                                                                    <td>
                                                                        <!-- matchodds_bookmaker_profit_loss -->
                                                                        <span *ngIf="arrayObj.is_session_fancy == 'N'"
                                                                            id="profitData" class="profit"
                                                                            style="color: green">{{
                                                                            arrayObj.stake <= "0" ? "0" :
                                                                                arrayObj.is_back==0 ? arrayObj.stake :
                                                                                arrayObj.odds * arrayObj.stake -
                                                                                arrayObj.stake=="NaN" ? 0 : (
                                                                                arrayObj.odds * arrayObj.stake -
                                                                                arrayObj.stake ).toFixed(2) }}</span>
                                                                                <!-- fancy_profit_loss -->
                                                                                <span
                                                                                    *ngIf="arrayObj.is_session_fancy == 'Y'"
                                                                                    id="profitData" class="profit"
                                                                                    style="color: green">{{
                                                                                    arrayObj.is_back == 0
                                                                                    ? arrayObj.stake
                                                                                    : (
                                                                                    (arrayObj.size * arrayObj.stake) /
                                                                                    100
                                                                                    ).toFixed(2)
                                                                                    }}</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <!-- stake_value -->
                                                        <div class="betsnumbers">
                                                            <div class="betslip-button text-center"
                                                                style="margin-bottom: 5px">
                                                                <div style="
                                      display: flex;
                                      width: 100%;
                                      vertical-align: middle;
                                    ">
                                                                    <div class="scrollmenu">
                                                                        <div class="tab-btn-card" *ngFor="
                                          let stake of userMatchStack;
                                          let i = index
                                        " (click)="
                                          calculateP_lOnStack(
                                            arrayObj.odds * arrayObj.stake -
                                              arrayObj.stake,
                                            stake.price,
                                            arrayObj.is_back,
                                            arrayObj
                                          )
                                        ">
                                                                            {{ stake.label }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="btnsectionbet">
                                                            <button class="btn btnreset"
                                                                (click)="Place_bet_sec = false">
                                                                Reset
                                                            </button>
                                                            <button class="btn btnsend"
                                                                [disabled]="arrayObj.stake === 0 || betloder"
                                                                (click)="saveFancyBet(arrayObj)"
                                                                *ngIf="arrayObj.is_fancy == 1">
                                                                Submit
                                                            </button>
                                                            <button class="btn btnsend"
                                                                [disabled]="arrayObj.stake === 0 || betloder"
                                                                (click)="saveBet(arrayObj)"
                                                                *ngIf="arrayObj.is_fancy == 0">
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!---->
                                            </div>
                                            <div class="card m-b-10 my-bet">
                                                <div class="card-header">
                                                    <h6 class="card-title d-inline-block">My Bet</h6>
                                                </div>
                                                <div class="card-body">
                                                    <table class="coupon-table table table-borderedless">
                                                        <thead>
                                                            <tr>
                                                                <th style="width: 60%">Matched Bet</th>
                                                                <th class="text-right">Odds</th>
                                                                <th class="text-center">Stake</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody *ngFor="let bet of allBetData">
                                                            <tr [ngClass]="{
                                    lay: bet?.type == 'Lay',
                                    back: bet?.type == 'Back'
                                  }">
                                                                <!-- matchOdds/bookmaker -->
                                                                <ng-container *ngIf="
                                      bet?.marketType === 'MATCH_ODDS' ||
                                        bet?.marketType === 'Special';
                                      else else_session_block
                                    ">
                                                                    <td>{{ bet?.selectionName }}</td>
                                                                    <td class="text-right">
                                                                        <span *ngIf="
                                          bet.marketType === 'Special';
                                          else rate_matchOdds
                                        ">{{
                                                                            bet.rate * 100 - 100 | number : "0.0-2"
                                                                            }}</span>
                                                                        <ng-template #rate_matchOdds>{{
                                                                            bet?.rate | number : "0.0-2"
                                                                            }}</ng-template>
                                                                    </td>
                                                                </ng-container>

                                                                <!-- session -->
                                                                <ng-template #else_session_block>
                                                                    <td>
                                                                        {{ bet?.marketName }}
                                                                        <span>
                                                                            / {{ bet?.rate * 100 | number : ".0-2" }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-right">
                                                                        <span>{{ bet?.selectionName }}</span>
                                                                    </td>
                                                                </ng-template>
                                                                <!-- amount -->
                                                                <td style="text-align: right">
                                                                    {{ bet?.stake }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div class="text-warning" style="color: black !important"
                                                        *ngIf="allBetDataLength == 0">
                                                        No Bet Found
                                                    </div>
                                                </div>
                                            </div>
                                            <!---->
                                        </div>
                                        <div class="ps__rail-x" style="left: 0px; bottom: 0px">
                                            <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px"></div>
                                        </div>
                                        <div class="ps__rail-y" style="top: 0px; right: 0px">
                                            <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- desktop_footer -->
                <app-desk-footer></app-desk-footer>
            </div>
            <!--///////////////Desktop end/////////////////////////-->

            <!--///////////////Mobile start/////////////////////////-->
            <ng-container *ngIf="internetConn && !deviceInfo">
                <mat-card *ngIf="page_type === 'diamond'" fxLayout="column" class="detail-card">
                    <mat-tab-group class="tab-body" class="sport-details-tab">
                        <!-- 1st tab -->
                        <mat-tab label="ODDS">
                            <div fxLayout="row" fxLayoutAlign="start center" class="detail-header">
                                <!-- <span class="sport-icon" fxFlex="28px"><img src="assets/icons/4.png"></span> -->
                                <label fxFlex="50%" style="text-align: start">
                                    <span class="match-title">{{ matchName }}</span>
                                </label>
                                <label fxFlex="50%" style="text-align: end">
                                    <span class="date-time">{{
                                        matchDate | date : "MM/d/yy hh:mm aa"
                                        }}</span>
                                </label>
                                <!-- <mat-icon (click)="openModalCondition(Terms)">info</mat-icon>
                              <span class="material-icons green-color" *ngIf="ringOn == true" (click)="volumeOn(0)">volume_up </span>
                              <span class="material-icons red-color" *ngIf="ringOn == false" (click)="volumeOn(1)">volume_off </span> -->
                                <!-- <mat-icon (click)="openTvDiv();this.liveScoreStatus=false">live_tv</mat-icon> -->
                                <i (click)="openTvDiv(); this.liveScoreStatus = false" class="fa fa-tv"></i>
                                <mat-icon (click)="openScoreDiv(); this.liveTVStatus = false"
                                    class="livetv">score</mat-icon>
                                <!-- <mat-icon (click)="openModalAddMarket(addMarket);homematches()">add</mat-icon> -->
                            </div>
                            <!-- score board and tv -->
                            <mat-card *ngIf="graphicTvUrl" style="height: 190px; background-color: black"
                                [ngClass]="liveScoreStatus ? 'liveTV-block' : 'liveTV-none'">
                                <mat-tab-group>
                                    <mat-tab label="Live Score">
                                        <!-- <mat-icon class="text-warning" [ngClass]="liveScoreStatus ? 'liveTV-block' : 'liveTV-none'" (click)="openScoreDiv()">close</mat-icon> -->
                                        <iframe id="fp_embed_player" [src]="graphicTvUrl" marginwidth="0"
                                            marginheight="0" frameborder="0" width="100%" height="190px" scrolling="yes"
                                            allowfullscreen="allowfullscreen"></iframe>
                                    </mat-tab>
                                </mat-tab-group>
                            </mat-card>
                            <mat-card *ngIf="liveUrl" style="height: 240px"
                                [ngClass]="liveTVStatus ? 'liveTV-block' : 'liveTV-none'">
                                <mat-tab-group>
                                    <mat-tab label="Live TV">
                                        <!-- <mat-icon class="text-warning" [ngClass]="liveTVStatus ? 'liveTV-block' : 'liveTV-none'" (click)="openTvDiv()">close</mat-icon> -->
                                        <iframe id="fp_embed_player" [src]="liveUrl" marginwidth="0" marginheight="0"
                                            frameborder="0" width="100%" height="240px" scrolling="no"
                                            allowfullscreen="allowfullscreen"></iframe>
                                    </mat-tab>
                                </mat-tab-group>
                            </mat-card>

                            <!-- matchodds -->
                            <mat-accordion *ngIf="matchoddMarket?.length > 0 && manualMatchStatus"
                                class="tab-body odd-detail" multi>
                                <mat-expansion-panel [expanded]="true" hideToggle style="margin-top: 10px" *ngFor="
                      let matchOddsData of matchoddMarket;
                      trackBy: itemTrackBy;
                      let mDTIndex = index
                    ">
                                    <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center"
                                        class="panel-title">
                                        <div fxLayout="row">
                                            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                                                <span class="market-title">
                                                    <!-- <mat-icon *ngIf="!panelOpenState1">expand_less</mat-icon>
                                                  <mat-icon *ngIf="panelOpenState1">expand_more</mat-icon> -->
                                                    <span class="text-white">matchOdds</span>
                                                    <!-- <img src="assets/images/transparent.gif"> -->
                                                </span>
                                                <div class="minMax-limit" data-toggle="tooltip" data-html="true"
                                                    title="Infomation" (click)="
                              openModalCondition(match_rules, 'Match Rules')
                            ">
                                                    <i class="fas fa-info-circle whiteicon"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-panel-title>

                                    <div class="row headersec text_lay_back" style="flex-flow: row">
                                        <div class="col-8" style="text-align: left">
                                            <div *ngIf="
                            matchodds_max_limit_check === true;
                            else matchodds_max_check
                          " class="text-start" style="padding: 2px 0; margin-left: 5px">
                                                <span>Min:{{ matchodds_min_limit | shortNumber }} Max:{{
                                                    matchodds_max_limit | shortNumber
                                                    }}</span>
                                            </div>
                                            <ng-template #matchodds_max_check>
                                                <div class="text-start" style="padding: 2px 0">
                                                    <span *ngIf="matchOddsData?.eventTypeId === '4'">Min:{{
                                                        userDetails?.setting[0]?.min_bet | shortNumber
                                                        }}
                                                        Max:{{
                                                        userDetails?.setting[0]?.max_bet | shortNumber
                                                        }}</span>
                                                    <span *ngIf="matchOddsData?.eventTypeId === '2'">Min:{{
                                                        userDetails?.setting[2]?.min_bet | shortNumber
                                                        }}
                                                        Max:{{
                                                        userDetails?.setting[2]?.max_bet | shortNumber
                                                        }}</span>
                                                    <span *ngIf="matchOddsData?.eventTypeId === '1'">Min:{{
                                                        userDetails?.setting[1]?.min_bet | shortNumber
                                                        }}
                                                        Max:{{
                                                        userDetails?.setting[1]?.max_bet | shortNumber
                                                        }}</span>
                                                </div>
                                            </ng-template>
                                        </div>

                                        <div class="col-2">
                                            <div class="text-center" style="
                            background: #a7d8fd;
                            width: 100%;
                            padding: 3px 2px 3px;
                            border-left: 1px solid #aaa;
                          ">
                                                <span style="font-size: 14px">BACK</span>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="text-center" style="
                            background: #f9c9d4;
                            width: 100%;
                            padding: 3px 2px 3px;
                            border-left: 1px solid #aaa;
                            border-right: 1px solid #aaa;
                          ">
                                                <span style="font-size: 14px">LAY</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="bottom-border" fxLayout="row" fxLayoutAlign="start center"
                                        fxLayoutGap="1%" *ngFor="
                        let runner of matchOddsData.marketBook.runners;
                        let i = index
                      ">
                                        <label fxLayoutAlign="start left" fxFlex="60%" style="padding-left: 10px">
                                            <span>{{
                                                searchRunner(
                                                matchOddsData?.runners,
                                                runner?.selectionId
                                                )
                                                }}</span>

                                            <span class="text-bold font-weight-bold" [ngClass]="{
                            red:
                              calProLoss(
                                matchOddsData.runners,
                                matchOddsData.marketBook,
                                i,
                                matchOddsData
                              ) < 0,
                            green:
                              calProLoss(
                                matchOddsData.runners,
                                matchOddsData.marketBook,
                                i,
                                matchOddsData
                              ) >= 0
                          }">
                                                {{
                                                calProLoss(
                                                matchOddsData?.runners,
                                                matchOddsData?.marketBook,
                                                i,
                                                matchOddsData
                                                ) | number : "1.0-0"
                                                }}
                                            </span>
                                        </label>

                                        <button fxLayout="column" fxLayoutAlign="center center" [ngClass]="
                          runner?.availableToBack?.price ===
                          runner?.availableToBack?.oprice
                            ? 'back-button'
                            : 'price_blinking'
                        " fxFlex="20%" *ngIf="runner?.availableToBack" (click)="
                          getOddsValue(
                            runner?.availableToBack?.price,
                            runner?.selectionId,
                            matchOddsData?.marketId,
                            matchOddsData?.marketName,
                            matchOddsData?.eventName,
                            1,
                            searchRunner(
                              matchOddsData?.runners,
                              runner?.selectionId
                            ),
                            matchOddsData?.marketType,
                            betPlace
                          )
                        ">
                                            <span class="odds-value">{{
                                                runner?.availableToBack != undefined
                                                ? runner?.availableToBack?.price
                                                : ""
                                                }}</span>
                                            <span class="odds-small-value">{{
                                                (runner?.availableToBack != undefined
                                                ? runner?.availableToBack?.size
                                                : ""
                                                ) | shortNumber
                                                }}</span>
                                        </button>
                                        <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                                            fxFlex="20%" *ngIf="!runner?.availableToBack">
                                            <span class="odds-value">-</span>
                                            <span class="odds-small-value">-</span>
                                        </button>
                                        <button fxLayout="column" fxLayoutAlign="center center" [ngClass]="
                          runner?.availableToLay?.price ===
                          runner?.availableToLay?.oprice
                            ? 'lay-button'
                            : 'price_blinking'
                        " fxFlex="20%" *ngIf="runner?.availableToLay" (click)="
                          getOddsValue(
                            runner?.availableToLay?.price,
                            runner?.selectionId,
                            matchOddsData?.marketId,
                            matchOddsData?.marketName,
                            matchOddsData?.eventName,
                            0,
                            searchRunner(
                              matchOddsData?.runners,
                              runner?.selectionId
                            ),
                            matchOddsData?.marketType,
                            betPlace
                          )
                        ">
                                            <span class="odds-value">{{
                                                runner?.availableToLay != undefined
                                                ? runner?.availableToLay?.price
                                                : ""
                                                }}</span>
                                            <span class="odds-small-value">{{
                                                (runner?.availableToLay != undefined
                                                ? runner?.availableToLay?.size
                                                : ""
                                                ) | shortNumber
                                                }}</span>
                                        </button>
                                        <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                                            fxFlex="20%" *ngIf="!runner?.availableToLay">
                                            <span class="odds-value">-</span>
                                            <span class="odds-small-value">-</span>
                                        </button>

                                        <div *ngIf="checkMatchOddStatus(matchOddsData)" class="suspend">
                                            <div class="suspend-status">SUSPENDED</div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>

                            <!-- TowinToss -->
                            <mat-accordion *ngIf="toWinTossMarket?.length > 0" class="tab-body odd-detail" multi>
                                <mat-expansion-panel [expanded]="true" hideToggle style="margin-top: 10px" *ngFor="
                      let matchOddsData of toWinTossMarket;
                      trackBy: itemTrackBy;
                      let mDTIndex = index
                    ">
                                    <!-- <mat-expansion-panel-header> -->
                                    <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center"
                                        class="panel-title">
                                        <div fxLayout="row">
                                            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                                                <span class="market-title">
                                                    <!-- <mat-icon *ngIf="!panelOpenState1">expand_less</mat-icon>
                                                  <mat-icon *ngIf="panelOpenState1">expand_more</mat-icon> -->
                                                    <span class="text-white">{{
                                                        matchOddsData?.marketName
                                                        }}</span>
                                                    <!-- <img src="assets/images/transparent.gif"> -->
                                                </span>
                                                <div class="minMax-limit" data-toggle="tooltip" data-html="true"
                                                    title="Infomation" (click)="
                              openModalCondition(match_rules, 'Match Rules')
                            ">
                                                    <i class="fas fa-info-circle whiteicon"></i>
                                                </div>
                                                <!-- <div class="minMax-limit"><span>Max Bet: {{matchOddsData?.maxlimit}}</span></div> -->
                                            </div>
                                        </div>
                                    </mat-panel-title>
                                    <div class="row headersec text_lay_back" style="flex-flow: row">
                                        <div class="col-8" style="text-align: left">
                                            <div *ngIf="
                            matchodds_max_limit_check === true;
                            else matchodds_max_check
                          " class="text-start" style="padding: 2px 0; margin-left: 5px">
                                                <span>Min:{{ matchodds_min_limit | shortNumber }} Max:{{
                                                    matchodds_max_limit | shortNumber
                                                    }}</span>
                                            </div>
                                            <ng-template #matchodds_max_check>
                                                <div class="text-start" style="padding: 2px 0">
                                                    <span *ngIf="matchOddsData?.eventTypeId === '4'">Min:{{
                                                        userDetails?.setting[0]?.min_bet | shortNumber
                                                        }}
                                                        Max:{{
                                                        userDetails?.setting[0]?.max_bet | shortNumber
                                                        }}</span>
                                                    <span *ngIf="matchOddsData?.eventTypeId === '2'">Min:{{
                                                        userDetails?.setting[2]?.min_bet | shortNumber
                                                        }}
                                                        Max:{{
                                                        userDetails?.setting[2]?.max_bet | shortNumber
                                                        }}</span>
                                                    <span *ngIf="matchOddsData?.eventTypeId === '1'">Min:{{
                                                        userDetails?.setting[1]?.min_bet | shortNumber
                                                        }}
                                                        Max:{{
                                                        userDetails?.setting[1]?.max_bet | shortNumber
                                                        }}</span>
                                                </div>
                                            </ng-template>
                                        </div>

                                        <div class="col-2">
                                            <div class="text-center" style="
                            background: #a7d8fd;
                            width: 100%;
                            padding: 3px 2px 3px;
                            border-left: 1px solid #aaa;
                          ">
                                                <span style="font-size: 14px">BACK</span>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="text-center" style="
                            background: #f9c9d4;
                            width: 100%;
                            padding: 3px 2px 3px;
                            border-left: 1px solid #aaa;
                            border-right: 1px solid #aaa;
                          ">
                                                <span style="font-size: 14px">LAY</span>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="matchoddMarket?.message">
                                        <span class="text-danger text-center font-weight-bold">{{
                                            matchOddsData?.message
                                            }}</span>
                                    </ng-container>

                                    <div class="bottom-border" fxLayout="row" fxLayoutAlign="start center"
                                        fxLayoutGap="1%" *ngFor="
                        let runner of matchOddsData.marketBook.runners;
                        let i = index
                      ">
                                        <label fxLayoutAlign="start left" fxFlex="60%" style="padding-left: 10px">
                                            <span>{{
                                                searchRunner(
                                                matchOddsData?.runners,
                                                runner?.selectionId
                                                )
                                                }}</span>
                                            <span class="text-bold font-weight-bold" [ngClass]="{
                            red:
                              calProLoss(
                                matchOddsData.runners,
                                matchOddsData.marketBook,
                                i,
                                matchOddsData
                              ) < 0,
                            green:
                              calProLoss(
                                matchOddsData.runners,
                                matchOddsData.marketBook,
                                i,
                                matchOddsData
                              ) >= 0
                          }">
                                                {{
                                                calProLoss(
                                                matchOddsData?.runners,
                                                matchOddsData?.marketBook,
                                                i,
                                                matchOddsData
                                                ) | number : "1.0-0"
                                                }}
                                            </span>
                                        </label>
                                        <button fxLayout="column" fxLayoutAlign="center center" [ngClass]="
                          runner?.availableToBack?.price ===
                          runner?.availableToBack?.oprice
                            ? 'back-button'
                            : 'price_blinking'
                        " fxFlex="20%" *ngIf="runner?.availableToBack" (click)="
                          getOddsValue(
                            runner?.availableToBack?.price,
                            runner?.selectionId,
                            matchOddsData?.marketId,
                            matchOddsData?.marketName,
                            matchOddsData?.eventName,
                            1,
                            searchRunner(
                              matchOddsData?.runners,
                              runner?.selectionId
                            ),
                            matchOddsData?.marketType,
                            betPlace
                          )
                        ">
                                            <span class="odds-value">{{
                                                runner?.availableToBack != undefined
                                                ? runner?.availableToBack?.price
                                                : ""
                                                }}</span>
                                            <span class="odds-small-value">{{
                                                (runner?.availableToBack != undefined
                                                ? runner?.availableToBack?.size
                                                : ""
                                                ) | shortNumber
                                                }}</span>
                                        </button>
                                        <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                                            fxFlex="20%" *ngIf="!runner?.availableToBack">
                                            <span class="odds-value">-</span>
                                            <span class="odds-small-value">-</span>
                                        </button>
                                        <button fxLayout="column" fxLayoutAlign="center center" [ngClass]="
                          runner?.availableToLay?.price ===
                          runner?.availableToLay?.oprice
                            ? 'lay-button'
                            : 'price_blinking'
                        " fxFlex="20%" *ngIf="runner?.availableToLay" (click)="
                          getOddsValue(
                            runner?.availableToLay?.price,
                            runner?.selectionId,
                            matchOddsData?.marketId,
                            matchOddsData?.marketName,
                            matchOddsData?.eventName,
                            0,
                            searchRunner(
                              matchOddsData?.runners,
                              runner?.selectionId
                            ),
                            matchOddsData?.marketType,
                            betPlace
                          )
                        ">
                                            <span class="odds-value">{{
                                                runner?.availableToLay != undefined
                                                ? runner?.availableToLay?.price
                                                : ""
                                                }}</span>
                                            <span class="odds-small-value">{{
                                                (runner?.availableToLay != undefined
                                                ? runner?.availableToLay?.size
                                                : ""
                                                ) | shortNumber
                                                }}</span>
                                        </button>
                                        <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                                            fxFlex="20%" *ngIf="!runner?.availableToLay">
                                            <span class="odds-value">-</span>
                                            <span class="odds-small-value">-</span>
                                        </button>

                                        <div *ngIf="checkWinTossStatus(matchOddsData)" class="suspend">
                                            <div class="suspend-status">SUSPENDED</div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>

                            <!-- bookmaker -->
                            <mat-accordion *ngIf="bookmakerMarket?.length > 0" class="tab-body odd-detail bookmarktabel"
                                multi>
                                <mat-expansion-panel [expanded]="true" hideToggle style="margin-top: 10px" *ngFor="
                      let matchOddsData of bookmakerMarket;
                      trackBy: itemTrackBy;
                      let mDTIndex = index
                    ">
                                    <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center"
                                        class="panel-title">
                                        <div fxLayout="row">
                                            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                                                <span class="market-title">
                                                    <span class="text-white">{{
                                                        matchOddsData?.marketName
                                                        }}</span>
                                                    <!-- <img src="assets/images/transparent.gif"> -->
                                                </span>
                                                <div class="minMax-limit" data-toggle="tooltip" data-html="true"
                                                    title="Infomation" (click)="
                              openModalCondition(match_rules, 'Bookmaker Rules')
                            ">
                                                    <i class="fas fa-info-circle whiteicon"></i>
                                                </div>
                                                <!-- <div class="minMax-limit"><span>Max Bet: {{matchOddsData?.maxlimit}}</span></div> -->
                                            </div>
                                        </div>
                                    </mat-panel-title>
                                    <div class="row headersec text_lay_back" style="flex-flow: row">
                                        <div class="col-3" style="
                          text-align: left;
                          font-size: 12px;
                          font-weight: 600;
                        ">
                                            <ng-container *ngIf="matchOddsData.maxlimit; else bmax_min">
                                                <span>Min:{{ matchOddsData.minlimit | shortNumber }} Max:{{
                                                    matchOddsData.maxlimit | shortNumber
                                                    }}</span>
                                            </ng-container>
                                            <ng-template #bmax_min>
                                                <div *ngIf="
                              bookmaker_max_limit_check === true;
                              else bookmaker_max_check
                            " class="text-start" style="padding: 2px 0; margin-left: 5px">
                                                    <span>Min:{{ bookmaker_min_limit | shortNumber }} Max:{{
                                                        bookmaker_max_limit | shortNumber
                                                        }}</span>
                                                </div>
                                                <ng-template #bookmaker_max_check>
                                                    <div class="text-start" style="padding: 2px 0">
                                                        <span>Min:{{
                                                            userDetails?.setting[0]?.min_bet | shortNumber
                                                            }}
                                                            Max:{{
                                                            userDetails?.setting[0]?.max_bet | shortNumber
                                                            }}</span>
                                                    </div>
                                                </ng-template>
                                            </ng-template>
                                        </div>
                                        <div class="col-2"></div>
                                        <div class="col-2">
                                            <div class="text-center backcol" style="
                            background: #a7d8fd;
                            width: 100%;
                            padding: 3px 2px 3px;
                            border-left: 1px solid #aaa;
                          ">
                                                <span style="font-size: 14px">BACK</span>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="text-center laycol" style="
                            background: #f9c9d4;
                            width: 100%;
                            padding: 3px 2px 3px;
                            border-left: 1px solid #aaa;
                            border-right: 1px solid #aaa;
                          ">
                                                <span style="font-size: 14px">LAY</span>
                                            </div>
                                        </div>
                                        <div class="col-2"></div>
                                    </div>

                                    <div class="bottom-border" fxLayout="row" fxLayoutAlign="start center"
                                        fxLayoutGap="1%" *ngFor="
                        let runner of matchOddsData.marketBook.runners;
                        let i = index
                      ">
                                        <label fxLayoutAlign="start left" fxFlex="70%" style="padding-left: 10px">
                                            <span>{{
                                                searchRunner(
                                                matchOddsData?.runners,
                                                runner?.selectionId
                                                )
                                                }}</span>
                                            <span class="text-bold font-weight-bold" [ngClass]="{
                            red:
                              calProLoss(
                                matchOddsData?.runners,
                                matchOddsData?.marketBook,
                                i,
                                matchOddsData
                              ) < 0,
                            green:
                              calProLoss(
                                matchOddsData?.runners,
                                matchOddsData?.marketBook,
                                i,
                                matchOddsData
                              ) >= 0
                          }">
                                                {{
                                                calProLoss(
                                                matchOddsData?.runners,
                                                matchOddsData?.marketBook,
                                                i,
                                                matchOddsData
                                                ) | number : "1.0-0"
                                                }}
                                            </span>
                                        </label>
                                        <button fxLayout="column" fxLayoutAlign="center center"
                                            class="back-button backcol2" fxFlex="17%">
                                            <span class="odds-value">0</span>
                                            <span class="odds-small-value">0.00</span>
                                        </button>
                                        <button fxLayout="column" fxLayoutAlign="center center"
                                            class="back-button backcol1" fxFlex="17%">
                                            <span class="odds-value">0</span>
                                            <span class="odds-small-value">0.00</span>
                                        </button>
                                        <button fxLayout="column" fxLayoutAlign="center center"
                                            class="back-button backcol" fxFlex="17%" *ngIf="runner?.availableToBack"
                                            (click)="
                          getOddsValue(
                            runner?.availableToBack?.price,
                            runner?.selectionId,
                            matchOddsData?.marketId,
                            matchOddsData?.marketName,
                            matchOddsData?.eventName,
                            1,
                            searchRunner(
                              matchOddsData?.runners,
                              runner?.selectionId
                            ),
                            matchOddsData?.marketType,
                            betPlace
                          )
                        ">
                                            <span class="odds-value">{{
                                                runner?.availableToBack != undefined
                                                ? runner?.availableToBack?.kprice
                                                : ""
                                                }}</span>
                                            <span class="odds-small-value">{{
                                                (runner?.availableToBack != undefined
                                                ? runner?.availableToBack?.size
                                                : ""
                                                ) | shortNumber
                                                }}</span>
                                        </button>
                                        <button fxLayout="column" fxLayoutAlign="center center"
                                            class="back-button backcol" fxFlex="17%" *ngIf="!runner?.availableToBack">
                                            <span class="odds-value">0</span>
                                            <span class="odds-small-value">0.00</span>
                                        </button>
                                        <button fxLayout="column" fxLayoutAlign="center center"
                                            class="lay-button laycol" fxFlex="17%" *ngIf="runner?.availableToLay"
                                            (click)="
                          getOddsValue(
                            runner?.availableToLay?.price,
                            runner?.selectionId,
                            matchOddsData?.marketId,
                            matchOddsData?.marketName,
                            matchOddsData?.eventName,
                            0,
                            searchRunner(
                              matchOddsData?.runners,
                              runner?.selectionId
                            ),
                            matchOddsData?.marketType,
                            betPlace
                          )
                        ">
                                            <span class="odds-value">{{
                                                runner?.availableToLay != undefined
                                                ? runner?.availableToLay?.kprice
                                                : ""
                                                }}</span>
                                            <span class="odds-small-value">{{
                                                (runner?.availableToLay != undefined
                                                ? runner?.availableToLay?.size
                                                : ""
                                                ) | shortNumber
                                                }}</span>
                                        </button>
                                        <button fxLayout="column" fxLayoutAlign="center center"
                                            class="lay-button laycol" fxFlex="17%" *ngIf="!runner?.availableToLay">
                                            <span class="odds-value">0</span>
                                            <span class="odds-small-value">0.00</span>
                                        </button>

                                        <button fxLayout="column" fxLayoutAlign="center center"
                                            class="lay-button laycol1" fxFlex="17%">
                                            <span class="odds-value">0</span>
                                            <span class="odds-small-value">0.00</span>
                                        </button>

                                        <button fxLayout="column" fxLayoutAlign="center center"
                                            class="lay-button laycol2" fxFlex="17%">
                                            <span class="odds-value">0</span>
                                            <span class="odds-small-value">0.00</span>
                                        </button>

                                        <div class="suspendBookmaker"
                                            *ngIf="checkBookmakerStatus(matchOddsData, runner)">
                                            <div class="suspend-status">SUSPENDED</div>
                                        </div>
                                    </div>
                                    <p class="createBy_message text-right font-weight-bold">
                                        {{ matchOddsData?.createdBy }}
                                    </p>
                                </mat-expansion-panel>
                            </mat-accordion>

                            <!-- fancyButton -->
                            <ng-container *ngIf="eventTypeId == '4'">
                                <ul class="nav nav-tabs mt-2 fancy-nav">
                                    <li class="nav-item" [ngClass]="{ active: fancy_click === true }"
                                        (click)="fancy_click = true; meter_click = false">
                                        <a data-toggle="tab" class="nav-link">Fancy</a>
                                    </li>
                                    <!-- <li class="nav-item" [ngClass]="{'active': fancy1_click === true}" (click)="fancy_click=false;fancy1_click=true;odd_even_click=false;"><a data-toggle="tab" class="nav-link">Fancy 1</a></li>
                              <li class="nav-item" [ngClass]="{'active': odd_even_click === true }" (click)="fancy_click=false;odd_even_click=true;fancy1_click=false;"><a data-toggle="tab" class="nav-link">Odd Even</a></li> -->
                                    <li class="nav-item" [ngClass]="{ active: meter_click === true }">
                                        <a data-toggle="tab" class="nav-link"
                                            (click)="meter_click = true; fancy_click = false">Meter</a>
                                    </li>
                                    <!-- <li class="nav-item"><a data-toggle="tab" class="nav-link">Four</a></li>
                              <li class="nav-item"><a data-toggle="tab" class="nav-link">Six</a></li>
                              <li class="nav-item"><a data-toggle="tab" class="nav-link">Cricket Casino</a></li> -->
                                </ul>
                                <ng-container *ngIf="sessionMarket?.length == 0">
                                    <div style="background-color: #cccccc" class="text-dark text-center p-2">
                                        No real-time records found
                                    </div>
                                </ng-container>
                            </ng-container>
                            <!-- session -->
                            <mat-accordion *ngIf="sessionMarket?.length > 0" class="tab-body odd-detail" multi>
                                <!-- normal_fancy -->
                                <ng-container *ngIf="fancy_click">
                                    <!-- session_market -->
                                    <mat-expansion-panel [expanded]="true" hideToggle>
                                        <div class="row headersec text_lay_back" style="flex-flow: row">
                                            <div class="col-8" style="text-align: left">
                                                <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center"
                                                    class="panel-title">
                                                    <div fxLayout="row">
                                                        <div fxLayout="row" fxLayoutAlign="space-between center"
                                                            fxLayoutGap="5px">
                                                            <span class="market-title">
                                                                <span class="text-white">Session Market</span>
                                                            </span>
                                                            <div fxLayout="row">
                                                                <div class="minMax-limit" (click)="
                                      openModalCondition(
                                        match_rules,
                                        'Fancy Rules'
                                      )
                                    ">
                                                                    <i class="fas fa-info-circle whiteicon"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </mat-panel-title>
                                                <!-- <div class="text-start" style="padding: 5px 0"><span>Max Bet: 100000</span>
                                          </div> -->
                                            </div>
                                            <div class="col-2">
                                                <div class="text-center" style="
                              background: #f9c9d4;
                              width: 100%;
                              padding: 5px 2px;
                              border-left: 1px solid #aaa;
                            ">
                                                    <span style="font-size: 14px">No</span>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="text-center" style="
                              background: #a7d8fd;
                              width: 100%;
                              padding: 5px 2px;
                              border-left: 1px solid #aaa;
                              border-right: 1px solid #aaa;
                            ">
                                                    <span style="font-size: 14px">Yes</span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- </mat-expansion-panel-header> -->

                                        <div *ngFor="
                          let runner of sessionMarket;
                          trackBy: itemTrackBy;
                          let mDTIndex = index
                        ">
                                            <div class="bottom-border p-0" fxLayout="row" fxLayoutAlign="start center"
                                                fxLayoutGap="1%">
                                                <label fxLayoutAlign="start left" fxFlex="60%"
                                                    style="padding-left: 10px; cursor: pointer"
                                                    (click)="session_book(sessionBook, runner?.marketId)">
                                                    <span>{{ runner?.marketName }}</span>
                                                    <p class="font-weight-bold" *ngIf="sessionMarketExposure">
                                                        <span class="red"
                                                            *ngIf="sessionMarketExposure[runner.marketId]">
                                                            {{ sessionMarketExposure[runner.marketId] }}
                                                        </span>

                                                        <!-- <span *ngIf="!sessionMarketExposure[runner.marketId]">
                                                 0
                                              </span> -->
                                                    </p>

                                                    <!-- <span class="green" *ngIf="objectSessionExposure">
                                                  <span *ngIf="objectSessionExposure[runner.marketId]">
  
                                                  </span> {{objectSessionExposure[runner.marketId]}}
                                              </span> -->
                                                </label>

                                                <div [matMenuTriggerFor]="menu">
                                                    <i class="fas fa-info-circle blackicon"></i>
                                                    <mat-menu #menu="matMenu" yPosition="below">
                                                        <ng-container *ngIf="runner.maxlimit; else nfancy_assites">
                                                            <button mat-menu-item>
                                                                Min:{{ runner.minlimit | shortNumber }}
                                                            </button>
                                                            <button mat-menu-item>
                                                                Max:{{ runner.maxlimit | shortNumber }}
                                                            </button>
                                                        </ng-container>
                                                        <ng-template #nfancy_assites>
                                                            <ng-container *ngIf="
                                    fancy_max_limit_check === true;
                                    else normal_session
                                  ">
                                                                <button mat-menu-item>
                                                                    Min:{{ fancy_min_limit | shortNumber }}
                                                                </button>
                                                                <button mat-menu-item>
                                                                    Max:{{ fancy_max_limit | shortNumber }}
                                                                </button>
                                                            </ng-container>
                                                            <ng-template #normal_session>
                                                                <button mat-menu-item>
                                                                    Min:{{
                                                                    userDetails?.setting[0]?.min_bet
                                                                    | shortNumber
                                                                    }}
                                                                </button>
                                                                <button mat-menu-item>
                                                                    Max:{{
                                                                    userDetails?.setting[0]?.max_bet
                                                                    | shortNumber
                                                                    }}
                                                                </button>
                                                            </ng-template>
                                                        </ng-template>
                                                    </mat-menu>
                                                </div>

                                                <button fxLayout="column" fxLayoutAlign="center center"
                                                    class="lay-button" fxFlex="20%" (click)="
                              setSessionValue(
                                runner?.marketBook?.availableToLay?.price,
                                runner?.marketId,
                                runner?.marketName,
                                runner?.eventName,
                                0,
                                runner?.marketBook?.availableToLay?.size,
                                runner?.marketType,
                                betPlace
                              )
                            ">
                                                    <span class="odds-value">{{
                                                        runner?.marketBook?.availableToLay != undefined
                                                        ? runner?.marketBook?.availableToLay?.price
                                                        : ""
                                                        }}</span>
                                                    <span class="odds-small-value">{{
                                                        (runner?.marketBook?.availableToLay != undefined
                                                        ? runner?.marketBook?.availableToLay?.size
                                                        : ""
                                                        ) | shortNumber
                                                        }}</span>
                                                </button>
                                                <button fxLayout="column" fxLayoutAlign="center center"
                                                    class="back-button" fxFlex="20%" *ngIf="
                              runner?.marketBook?.availableToLay?.length == 0
                            ">
                                                    <span>-</span>
                                                    <span>-</span>
                                                </button>
                                                <button fxLayout="column" fxLayoutAlign="center center"
                                                    class="back-button" fxFlex="20%" (click)="
                              setSessionValue(
                                runner?.marketBook?.availableToBack?.price,
                                runner?.marketId,
                                runner?.marketName,
                                runner?.eventName,
                                1,
                                runner?.marketBook?.availableToBack?.size,
                                runner?.marketType,
                                betPlace
                              )
                            ">
                                                    <span class="odds-value">{{
                                                        runner?.marketBook?.availableToBack != undefined
                                                        ? runner?.marketBook?.availableToBack?.price
                                                        : ""
                                                        }}</span>
                                                    <span class="odds-small-value">{{
                                                        (runner?.marketBook?.availableToBack != undefined
                                                        ? runner?.marketBook?.availableToBack?.size
                                                        : ""
                                                        ) | shortNumber
                                                        }}</span>
                                                </button>
                                                <button fxLayout="column" fxLayoutAlign="center center"
                                                    class="lay-button" fxFlex="20%" *ngIf="
                              runner?.marketBook?.availableToBack?.length == 0
                            ">
                                                    <span>-</span>
                                                    <span>-</span>
                                                </button>

                                                <div class="suspend" *ngIf="
                              runner?.marketBook?.statusLabel == 'Ball Running'
                            ">
                                                    <div class="suspend-status">BALL RUNNING</div>
                                                </div>
                                                <div class="suspend"
                                                    *ngIf="runner?.marketBook?.statusLabel == 'SUSPENDED'">
                                                    <div class="suspend-status">SUSPENDED</div>
                                                </div>
                                            </div>
                                            <p *ngIf="runner.message" class="runmessagenew" style="
                            color: #8c2110;
                            padding: 0 4px;
                            margin: 0px;
                            text-align: right;
                            font-weight: bold;
                          ">
                                                {{ runner.message }}
                                            </p>
                                        </div>
                                    </mat-expansion-panel>

                                    <!--fancy_over_by_over_session_market  -->
                                    <mat-expansion-panel [expanded]="true" hideToggle (opened)="panelOpenState1 = true"
                                        (closed)="panelOpenState1 = false">
                                        <div class="row headersec text_lay_back" style="flex-flow: row">
                                            <div class="col-8" style="text-align: left">
                                                <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center"
                                                    class="panel-title">
                                                    <div fxLayout="row">
                                                        <div fxLayout="row" fxLayoutAlign="space-between center"
                                                            fxLayoutGap="5px">
                                                            <span class="market-title">
                                                                <span class="text-white">Over By Over Session
                                                                    Market</span>
                                                            </span>
                                                            <div fxLayout="row">
                                                                <div class="minMax-limit" (click)="
                                      openModalCondition(
                                        match_rules,
                                        'Fancy Rules'
                                      )
                                    ">
                                                                    <i class="fas fa-info-circle whiteicon"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </mat-panel-title>
                                            </div>
                                            <div class="col-2">
                                                <div class="text-center" style="
                              background: #f9c9d4;
                              width: 100%;
                              padding: 5px 2px;
                              border-left: 1px solid #aaa;
                            ">
                                                    <span style="font-size: 14px">No</span>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="text-center" style="
                              background: #a7d8fd;
                              width: 100%;
                              padding: 5px 2px;
                              border-left: 1px solid #aaa;
                              border-right: 1px solid #aaa;
                            ">
                                                    <span style="font-size: 14px">Yes</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngFor="
                          let runner of over_by_over_Market;
                          trackBy: itemTrackBy;
                          let mDTIndex = index
                        ">
                                            <div class="bottom-border" fxLayout="row" fxLayoutAlign="start center"
                                                fxLayoutGap="1%">
                                                <label fxLayoutAlign="start left" fxFlex="60%"
                                                    style="padding-left: 10px; cursor: pointer"
                                                    (click)="session_book(sessionBook, runner?.marketId)">
                                                    <span>{{ runner?.marketName }}</span>
                                                    <p *ngIf="sessionMarketExposure">
                                                        <span class="red"
                                                            *ngIf="sessionMarketExposure[runner.marketId]">
                                                            {{ sessionMarketExposure[runner.marketId] }}
                                                        </span>

                                                        <!-- <span *ngIf="!sessionMarketExposure[runner.marketId]">
                                                 0
                                              </span> -->
                                                    </p>
                                                </label>
                                                <div [matMenuTriggerFor]="menu">
                                                    <i class="fas fa-info-circle blackicon"></i>
                                                    <mat-menu #menu="matMenu" yPosition="below">
                                                        <ng-container *ngIf="runner.maxlimit; else ofancy_assites">
                                                            <button mat-menu-item>
                                                                Min:{{ runner.minlimit | shortNumber }}
                                                            </button>
                                                            <button mat-menu-item>
                                                                Max:{{ runner.maxlimit | shortNumber }}
                                                            </button>
                                                        </ng-container>
                                                        <ng-template #ofancy_assites>
                                                            <ng-container *ngIf="
                                    fancy_max_limit_check === true;
                                    else normal_session
                                  ">
                                                                <button mat-menu-item>
                                                                    Min:{{ fancy_min_limit | shortNumber }}
                                                                </button>
                                                                <button mat-menu-item>
                                                                    Max:{{ fancy_max_limit | shortNumber }}
                                                                </button>
                                                            </ng-container>
                                                            <ng-template #normal_session>
                                                                <button mat-menu-item>
                                                                    Min:{{
                                                                    userDetails?.setting[0]?.min_bet
                                                                    | shortNumber
                                                                    }}
                                                                </button>
                                                                <button mat-menu-item>
                                                                    Max:{{
                                                                    userDetails?.setting[0]?.max_bet
                                                                    | shortNumber
                                                                    }}
                                                                </button>
                                                            </ng-template>
                                                        </ng-template>
                                                    </mat-menu>
                                                </div>

                                                <button fxLayout="column" fxLayoutAlign="center center"
                                                    class="lay-button" fxFlex="20%" (click)="
                              setSessionValue(
                                runner?.marketBook?.availableToLay?.price,
                                runner?.marketId,
                                runner?.marketName,
                                runner?.eventName,
                                0,
                                runner?.marketBook?.availableToLay?.size,
                                runner?.marketType,
                                betPlace
                              )
                            ">
                                                    <span class="odds-value">{{
                                                        runner?.marketBook?.availableToLay != undefined
                                                        ? runner?.marketBook?.availableToLay?.price
                                                        : ""
                                                        }}</span>
                                                    <span class="odds-small-value">{{
                                                        (runner?.marketBook?.availableToLay != undefined
                                                        ? runner?.marketBook?.availableToLay?.size
                                                        : ""
                                                        ) | shortNumber
                                                        }}</span>
                                                </button>
                                                <button fxLayout="column" fxLayoutAlign="center center"
                                                    class="back-button" fxFlex="20%" *ngIf="
                              runner?.marketBook?.availableToLay?.length == 0
                            ">
                                                    <span>-</span>
                                                    <span>-</span>
                                                </button>
                                                <button fxLayout="column" fxLayoutAlign="center center"
                                                    class="back-button" fxFlex="20%" (click)="
                              setSessionValue(
                                runner?.marketBook?.availableToBack?.price,
                                runner?.marketId,
                                runner?.marketName,
                                runner?.eventName,
                                1,
                                runner?.marketBook?.availableToBack?.size,
                                runner?.marketType,
                                betPlace
                              )
                            ">
                                                    <span class="odds-value">{{
                                                        runner?.marketBook?.availableToBack != undefined
                                                        ? runner?.marketBook?.availableToBack?.price
                                                        : ""
                                                        }}</span>
                                                    <span class="odds-small-value">{{
                                                        (runner?.marketBook?.availableToBack != undefined
                                                        ? runner?.marketBook?.availableToBack?.size
                                                        : ""
                                                        ) | shortNumber
                                                        }}</span>
                                                </button>
                                                <button fxLayout="column" fxLayoutAlign="center center"
                                                    class="lay-button" fxFlex="20%" *ngIf="
                              runner?.marketBook?.availableToBack?.length == 0
                            ">
                                                    <span>-</span>
                                                    <span>-</span>
                                                </button>

                                                <div class="suspend" *ngIf="
                              runner?.marketBook?.statusLabel == 'Ball Running'
                            ">
                                                    <div class="suspend-status">Ball runnig</div>
                                                </div>
                                                <div class="suspend"
                                                    *ngIf="runner?.marketBook?.statusLabel == 'SUSPENDED'">
                                                    <div class="suspend-status">SUSPENDED</div>
                                                </div>
                                            </div>
                                            <!-- <p  class="runmessagenew" style="color:#000;padding: 0 4px; margin: 0px;text-align:right;">{{runner.message}}</p> -->
                                            <p *ngIf="runner.message" class="runmessagenew" style="
                            color: #8c2110;
                            padding: 0 4px;
                            margin: 0px;
                            text-align: right;
                            font-weight: bold;
                          ">
                                                {{ runner.message }}
                                            </p>
                                        </div>
                                    </mat-expansion-panel>

                                    <!--fancy_ball_by_ball_session_market  -->
                                    <mat-expansion-panel [expanded]="true" hideToggle (opened)="panelOpenState1 = true"
                                        (closed)="panelOpenState1 = false">
                                        <div class="row headersec text_lay_back" style="flex-flow: row">
                                            <div class="col-8" style="text-align: left">
                                                <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center"
                                                    class="panel-title">
                                                    <div fxLayout="row">
                                                        <div fxLayout="row" fxLayoutAlign="space-between center"
                                                            fxLayoutGap="5px">
                                                            <span class="market-title">
                                                                <span class="text-white">Ball By Ball Session
                                                                    Market</span>
                                                            </span>
                                                            <div fxLayout="row">
                                                                <div class="minMax-limit" (click)="
                                      openModalCondition(
                                        match_rules,
                                        'Fancy Rules'
                                      )
                                    ">
                                                                    <i class="fas fa-info-circle whiteicon"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </mat-panel-title>
                                            </div>
                                            <div class="col-2">
                                                <div class="text-center" style="
                              background: #f9c9d4;
                              width: 100%;
                              padding: 5px 2px;
                              border-left: 1px solid #aaa;
                            ">
                                                    <span style="font-size: 14px">No</span>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="text-center" style="
                              background: #a7d8fd;
                              width: 100%;
                              padding: 5px 2px;
                              border-left: 1px solid #aaa;
                              border-right: 1px solid #aaa;
                            ">
                                                    <span style="font-size: 14px">Yes</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngFor="
                          let runner of ball_by_ballMarket;
                          trackBy: itemTrackBy;
                          let mDTIndex = index
                        ">
                                            <div class="bottom-border" fxLayout="row" fxLayoutAlign="start center"
                                                fxLayoutGap="1%">
                                                <label fxLayoutAlign="start left" fxFlex="60%"
                                                    style="padding-left: 10px; cursor: pointer"
                                                    (click)="session_book(sessionBook, runner?.marketId)">
                                                    <span>{{ runner?.marketName }}</span>
                                                    <p *ngIf="sessionMarketExposure">
                                                        <span class="red"
                                                            *ngIf="sessionMarketExposure[runner.marketId]">
                                                            {{ sessionMarketExposure[runner.marketId] }}
                                                        </span>

                                                        <!-- <span *ngIf="!sessionMarketExposure[runner.marketId]">
                                                 0
                                              </span> -->
                                                    </p>
                                                </label>
                                                <div [matMenuTriggerFor]="menu">
                                                    <i class="fas fa-info-circle blackicon"></i>
                                                    <mat-menu #menu="matMenu" yPosition="below">
                                                        <ng-container *ngIf="runner.maxlimit; else bfancy_assites">
                                                            <button mat-menu-item>
                                                                Min:{{ runner.minlimit | shortNumber }}
                                                            </button>
                                                            <button mat-menu-item>
                                                                Max:{{ runner.maxlimit | shortNumber }}
                                                            </button>
                                                        </ng-container>
                                                        <ng-template #bfancy_assites>
                                                            <ng-container *ngIf="
                                    fancy_max_limit_check === true;
                                    else normal_session
                                  ">
                                                                <button mat-menu-item>
                                                                    Min:{{ fancy_min_limit | shortNumber }}
                                                                </button>
                                                                <button mat-menu-item>
                                                                    Max:{{ fancy_max_limit | shortNumber }}
                                                                </button>
                                                            </ng-container>
                                                            <ng-template #normal_session>
                                                                <button mat-menu-item>
                                                                    Min:{{
                                                                    userDetails?.setting[0]?.min_bet
                                                                    | shortNumber
                                                                    }}
                                                                </button>
                                                                <button mat-menu-item>
                                                                    Max:{{
                                                                    userDetails?.setting[0]?.max_bet
                                                                    | shortNumber
                                                                    }}
                                                                </button>
                                                            </ng-template>
                                                        </ng-template>
                                                    </mat-menu>
                                                </div>

                                                <button fxLayout="column" fxLayoutAlign="center center"
                                                    class="lay-button" fxFlex="20%" (click)="
                              setSessionValue(
                                runner?.marketBook?.availableToLay?.price,
                                runner?.marketId,
                                runner?.marketName,
                                runner?.eventName,
                                0,
                                runner?.marketBook?.availableToLay?.size,
                                runner?.marketType,
                                betPlace
                              )
                            ">
                                                    <span class="odds-value">{{
                                                        runner?.marketBook?.availableToLay != undefined
                                                        ? runner?.marketBook?.availableToLay?.price
                                                        : ""
                                                        }}</span>
                                                    <span class="odds-small-value">{{
                                                        (runner?.marketBook?.availableToLay != undefined
                                                        ? runner?.marketBook?.availableToLay?.size
                                                        : ""
                                                        ) | shortNumber
                                                        }}</span>
                                                </button>
                                                <button fxLayout="column" fxLayoutAlign="center center"
                                                    class="back-button" fxFlex="20%" *ngIf="
                              runner?.marketBook?.availableToLay?.length == 0
                            ">
                                                    <span>-</span>
                                                    <span>-</span>
                                                </button>
                                                <button fxLayout="column" fxLayoutAlign="center center"
                                                    class="back-button" fxFlex="20%" (click)="
                              setSessionValue(
                                runner?.marketBook?.availableToBack?.price,
                                runner?.marketId,
                                runner?.marketName,
                                runner?.eventName,
                                1,
                                runner?.marketBook?.availableToBack?.size,
                                runner?.marketType,
                                betPlace
                              )
                            ">
                                                    <span class="odds-value">{{
                                                        runner?.marketBook?.availableToBack != undefined
                                                        ? runner?.marketBook?.availableToBack?.price
                                                        : ""
                                                        }}</span>
                                                    <span class="odds-small-value">{{
                                                        (runner?.marketBook?.availableToBack != undefined
                                                        ? runner?.marketBook?.availableToBack?.size
                                                        : ""
                                                        ) | shortNumber
                                                        }}</span>
                                                </button>
                                                <button fxLayout="column" fxLayoutAlign="center center"
                                                    class="lay-button" fxFlex="20%" *ngIf="
                              runner?.marketBook?.availableToBack?.length == 0
                            ">
                                                    <span>-</span>
                                                    <span>-</span>
                                                </button>

                                                <div class="suspend" *ngIf="
                              runner?.marketBook?.statusLabel == 'Ball Running'
                            ">
                                                    <div class="suspend-status">Ball runnig</div>
                                                </div>
                                                <div class="suspend"
                                                    *ngIf="runner?.marketBook?.statusLabel == 'SUSPENDED'">
                                                    <div class="suspend-status">SUSPENDED</div>
                                                </div>
                                            </div>
                                            <p *ngIf="runner.message" class="runmessagenew" style="
                            color: #8c2110;
                            padding: 0 4px;
                            margin: 0px;
                            text-align: right;
                            font-weight: bold;
                          ">
                                                {{ runner.message }}
                                            </p>
                                        </div>
                                    </mat-expansion-panel>
                                </ng-container>

                                <!-- meter_normal_fancy -->
                                <ng-container *ngIf="meter_click">
                                    <mat-expansion-panel [expanded]="true" hideToggle (opened)="panelOpenState1 = true"
                                        (closed)="panelOpenState1 = false">
                                        <div class="row headersec text_lay_back" style="flex-flow: row">
                                            <div class="col-8" style="text-align: left">
                                                <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center"
                                                    class="panel-title">
                                                    <div fxLayout="row">
                                                        <div fxLayout="row" fxLayoutAlign="space-between center"
                                                            fxLayoutGap="5px">
                                                            <span class="market-title">
                                                                <span class="text-white">Meter Market</span>
                                                            </span>
                                                            <div fxLayout="row">
                                                                <div class="minMax-limit" (click)="
                                      openModalCondition(
                                        match_rules,
                                        'Fancy Rules'
                                      )
                                    ">
                                                                    <i class="fas fa-info-circle whiteicon"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </mat-panel-title>
                                                <!-- <div class="text-start" style="padding: 5px 0"><span>Max Bet: 100000</span>
                                              </div> -->
                                            </div>
                                            <div class="col-2">
                                                <div class="text-center" style="
                              background: #f9c9d4;
                              width: 100%;
                              padding: 5px 2px;
                              border-left: 1px solid #aaa;
                            ">
                                                    <span style="font-size: 14px">No</span>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="text-center" style="
                              background: #a7d8fd;
                              width: 100%;
                              padding: 5px 2px;
                              border-left: 1px solid #aaa;
                              border-right: 1px solid #aaa;
                            ">
                                                    <span style="font-size: 14px">Yes</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngFor="
                          let runner of meterMarket;
                          trackBy: itemTrackBy;
                          let mDTIndex = index
                        " class="bottom-border" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%">
                                            <label fxLayoutAlign="start left" fxFlex="60%"
                                                style="padding-left: 10px; cursor: pointer"
                                                (click)="session_book(sessionBook, runner?.marketId)">
                                                <span>{{ runner?.marketName }}</span>
                                                <p *ngIf="sessionMarketExposure">
                                                    <span class="red" *ngIf="sessionMarketExposure[runner.marketId]">
                                                        {{ sessionMarketExposure[runner.marketId] }}
                                                    </span>

                                                    <!-- <span *ngIf="!sessionMarketExposure[runner.marketId]">
                                                     0
                                                  </span> -->
                                                </p>

                                                <!-- <span class="green" *ngIf="objectSessionExposure">
                                                  <span *ngIf="objectSessionExposure[runner.marketId]">
  
                                                  </span> {{objectSessionExposure[runner.marketId]}}
                                              </span> -->
                                            </label>

                                            <div [matMenuTriggerFor]="menu">
                                                <i class="fas fa-info-circle blackicon"></i>
                                                <mat-menu #menu="matMenu" yPosition="below">
                                                    <ng-container *ngIf="runner.maxlimit; else mnfancy_assites">
                                                        <button mat-menu-item>
                                                            Min:{{ runner.minlimit | shortNumber }}
                                                        </button>
                                                        <button mat-menu-item>
                                                            Max:{{ runner.maxlimit | shortNumber }}
                                                        </button>
                                                    </ng-container>
                                                    <ng-template #mnfancy_assites>
                                                        <ng-container *ngIf="
                                  fancy_max_limit_check === true;
                                  else normal_session
                                ">
                                                            <button mat-menu-item>
                                                                Min:{{ fancy_min_limit | shortNumber }}
                                                            </button>
                                                            <button mat-menu-item>
                                                                Max:{{ fancy_max_limit | shortNumber }}
                                                            </button>
                                                        </ng-container>
                                                        <ng-template #normal_session>
                                                            <button mat-menu-item>
                                                                Min:{{
                                                                userDetails?.setting[0]?.min_bet | shortNumber
                                                                }}
                                                            </button>
                                                            <button mat-menu-item>
                                                                Max:{{
                                                                userDetails?.setting[0]?.max_bet | shortNumber
                                                                }}
                                                            </button>
                                                        </ng-template>
                                                    </ng-template>
                                                </mat-menu>
                                            </div>

                                            <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                                                fxFlex="20%" (click)="
                            setSessionValue(
                              runner?.marketBook?.availableToLay?.price,
                              runner?.marketId,
                              runner?.marketName,
                              runner?.eventName,
                              0,
                              runner?.marketBook?.availableToLay?.size,
                              runner?.marketType,
                              betPlace
                            )
                          ">
                                                <span class="odds-value">{{
                                                    runner?.marketBook?.availableToLay != undefined
                                                    ? runner?.marketBook?.availableToLay?.price
                                                    : ""
                                                    }}</span>
                                                <span class="odds-small-value">{{
                                                    (runner?.marketBook?.availableToLay != undefined
                                                    ? runner?.marketBook?.availableToLay?.size
                                                    : ""
                                                    ) | shortNumber
                                                    }}</span>
                                            </button>
                                            <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                                                fxFlex="20%" *ngIf="runner?.marketBook?.availableToLay?.length == 0">
                                                <span>-</span>
                                                <span>-</span>
                                            </button>
                                            <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                                                fxFlex="20%" (click)="
                            setSessionValue(
                              runner?.marketBook?.availableToBack?.price,
                              runner?.marketId,
                              runner?.marketName,
                              runner?.eventName,
                              1,
                              runner?.marketBook?.availableToBack?.size,
                              runner?.marketType,
                              betPlace
                            )
                          ">
                                                <span class="odds-value">{{
                                                    runner?.marketBook?.availableToBack != undefined
                                                    ? runner?.marketBook?.availableToBack?.price
                                                    : ""
                                                    }}</span>
                                                <span class="odds-small-value">{{
                                                    (runner?.marketBook?.availableToBack != undefined
                                                    ? runner?.marketBook?.availableToBack?.size
                                                    : ""
                                                    ) | shortNumber
                                                    }}</span>
                                            </button>
                                            <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                                                fxFlex="20%" *ngIf="runner?.marketBook?.availableToBack?.length == 0">
                                                <span>-</span>
                                                <span>-</span>
                                            </button>

                                            <div class="suspend" *ngIf="
                            runner?.marketBook?.statusLabel == 'Ball Running'
                          ">
                                                <div class="suspend-status">Ball runnig</div>
                                            </div>
                                            <div class="suspend" *ngIf="runner?.marketBook?.statusLabel == 'SUSPENDED'">
                                                <div class="suspend-status">SUSPENDED</div>
                                            </div>
                                            <p *ngIf="runner.message" class="runmessagenew" style="
                            color: #8c2110;
                            padding: 0 4px;
                            margin: 0px;
                            text-align: right;
                          ">
                                                {{ runner.message }}
                                            </p>
                                        </div>
                                    </mat-expansion-panel>
                                </ng-container>

                                <!-- fancy1_market -->
                                <!-- <ng-container *ngIf="fancy1_click">
                              <mat-expansion-panel [expanded]="true" hideToggle
                                  (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
                                  
                                  <div class="row headersec text_lay_back" style="flex-flow: row;">
                                      <div class="col-8" style="text-align: left;">
                                          <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center"
                                          class="panel-title">
                                          <div fxLayout="row">
      
                                              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                                                  <span class="market-title">
                                                      <span class="text-white">Fancy1 Market</span>
                                                  </span>
                                                  <div fxLayout="row">
                                                      <div class="minMax-limit">
                                                          <img style="margin-right: 5px;" src="../../assets/images/icon/white_info-16.png" alt="">
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
      
                                      </mat-panel-title>
                                          
                                      </div>
                                      <div class="col-2" >
                                          <div class="text-center" style="background: #f9c9d4;width: 100%;padding: 5px 2px;border-left:1px solid #aaa;">
                                              <span style="font-size:14px">ODD</span>
                                          </div>
                                          
                                      </div>
                                      <div class="col-2">
                                          <div class="text-center"  style="background: #a7d8fd;width: 100%;padding: 5px 2px;border-left:1px solid #aaa;border-right:1px solid #aaa;" >
                                              <span style="font-size:14px">EVEN</span>
                                          </div>
                                      </div>
                                  </div>
                              
                                  <div *ngFor="let runner of fancy1Market;trackBy: itemTrackBy;let mDTIndex=index;"
                                      class="bottom-border" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%">
                                      <label fxLayoutAlign="start left" fxFlex="60%" style="padding-left: 10px;cursor:pointer;" (click)="session_book(sessionBook,runner)">
                                          <span>{{runner?.marketName}}</span>
                                          <p class="red" *ngIf="sessionMarketExposure">
                                              <span *ngIf="sessionMarketExposure[runner.marketId]">
                                                  -{{sessionMarketExposure[runner.marketId]}}
                                              </span>
  
                                              <span *ngIf="!sessionMarketExposure[runner.marketId]" class="red">
                                                 0
                                              </span>
                                          </p>
  
                                      </label>
                                      <div data-toggle="tooltip" data-html="true" title="Infomation">
                                          <i class="fas fa-info-circle blackicon"></i>
                                      </div>
                                      
                                      <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                                          fxFlex="20%"
                                          (click)="setSessionValue(runner?.marketBook?.availableToLay?.price,runner?.marketId,runner?.marketName,runner?.eventName,0,runner?.marketBook?.availableToLay?.size,runner?.marketType,runner?.selectionName)">
                                          <span class="odds-value">{{(runner?.marketBook?.availableToLay
                                              !=undefined?runner?.marketBook?.availableToLay?.price :'')}}</span>
                                          <span class="odds-small-value">{{(runner?.marketBook?.availableToLay
                                              !=undefined?(runner?.marketBook?.availableToLay?.size ) :'')}}</span>
                                      </button>
                                      <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                                          fxFlex="20%" *ngIf="runner?.marketBook?.availableToLay?.length == 0">
                                          <span>-</span>
                                          <span>-</span>
                                      </button>
                                      <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                                          fxFlex="20%"
                                          (click)="setSessionValue(runner?.marketBook?.availableToBack?.price,runner?.marketId,runner?.marketName,runner?.eventName,1,runner?.marketBook?.availableToBack?.size,runner?.marketType,runner?.selectionName)">
                                          <span class="odds-value">{{(runner?.marketBook?.availableToBack
                                              !=undefined?runner?.marketBook?.availableToBack?.price :'')}}</span>
                                          <span class="odds-small-value">{{(runner?.marketBook?.availableToBack
                                              !=undefined?(runner?.marketBook?.availableToBack?.size ) :'')}}</span>
                                      </button>
                                      <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                                          fxFlex="20%" *ngIf="runner?.marketBook?.availableToBack?.length == 0">
                                          <span>-</span>
                                          <span>-</span>
                                      </button>
  
                                      <div class="suspend" *ngIf="runner?.marketBook?.statusLabel=='Ball Running'">
                                          <div class="suspend-status">Ball runnig</div>
                                      </div>
                                      <div class="suspend" *ngIf="runner?.marketBook?.statusLabel=='SUSPENDED'">
                                          <div class="suspend-status">SUSPENDED</div>
                                      </div>
  
                                  </div>
  
                              </mat-expansion-panel>
  
                          </ng-container> -->

                                <!-- odd_even_market -->
                                <!-- <ng-container *ngIf="odd_even_click">
                              <mat-expansion-panel [expanded]="true" hideToggle
                                  (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
                                  
                                  <div class="row headersec text_lay_back" style="flex-flow: row;">
                                      <div class="col-8" style="text-align: left;">
                                          <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center"
                                          class="panel-title">
                                          <div fxLayout="row">
      
                                              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                                                  <span class="market-title">
                                                      <span class="text-white">Odd Even Session Market</span>
                                                  </span>
                                                  <div fxLayout="row">
                                                      <div class="minMax-limit">
                                                          <img style="margin-right: 5px;" src="../../assets/images/icon/white_info-16.png" alt="">
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
      
                                      </mat-panel-title>
                                          
                                      </div>
                                      <div class="col-2" >
                                          <div class="text-center" style="background: #f9c9d4;width: 100%;padding: 5px 2px;border-left:1px solid #aaa;">
                                              <span style="font-size:14px">ODD</span>
                                          </div>
                                          
                                      </div>
                                      <div class="col-2">
                                          <div class="text-center"  style="background: #a7d8fd;width: 100%;padding: 5px 2px;border-left:1px solid #aaa;border-right:1px solid #aaa;" >
                                              <span style="font-size:14px">EVEN</span>
                                          </div>
                                      </div>
                                  </div>
                              
                                  <div *ngFor="let runner of oddevenMarket;trackBy: itemTrackBy;let mDTIndex=index;"
                                      class="bottom-border" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%">
                                      <label fxLayoutAlign="start left" fxFlex="60%" style="padding-left: 10px;cursor:pointer;" (click)="session_book(sessionBook,runner)">
                                          <span>{{runner?.marketName}}</span>
                                          <p class="red" *ngIf="sessionMarketExposure">
                                              <span *ngIf="sessionMarketExposure[runner.marketId]">
                                                  -{{sessionMarketExposure[runner.marketId]}}
                                              </span>
  
                                              <span *ngIf="!sessionMarketExposure[runner.marketId]" class="red">
                                                 0
                                              </span>
                                          </p>
                                          
                                      </label>
                                      <div data-toggle="tooltip" data-html="true" title="Infomation">
                                          <i class="fas fa-info-circle blackicon"></i>
                                      </div>
                                      
                                      <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                                          fxFlex="20%"
                                          (click)="setSessionValue(runner?.marketBook?.availableToLay?.price,runner?.marketId,runner?.marketName,runner?.eventName,0,runner?.marketBook?.availableToLay?.size,runner?.marketType,runner?.selectionName)">
                                          <span class="odds-value">{{(runner?.marketBook?.availableToLay
                                              !=undefined?runner?.marketBook?.availableToLay?.price :'')}}</span>
                                          <span class="odds-small-value">{{(runner?.marketBook?.availableToLay
                                              !=undefined?(runner?.marketBook?.availableToLay?.size ) :'')}}</span>
                                      </button>
                                      <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                                          fxFlex="20%" *ngIf="runner?.marketBook?.availableToLay?.length == 0">
                                          <span>-</span>
                                          <span>-</span>
                                      </button>
                                      <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                                          fxFlex="20%"
                                          (click)="setSessionValue(runner?.marketBook?.availableToBack?.price,runner?.marketId,runner?.marketName,runner?.eventName,1,runner?.marketBook?.availableToBack?.size,runner?.marketType,runner?.selectionName)">
                                          <span class="odds-value">{{(runner?.marketBook?.availableToBack
                                              !=undefined?runner?.marketBook?.availableToBack?.price :'')}}</span>
                                          <span class="odds-small-value">{{(runner?.marketBook?.availableToBack
                                              !=undefined?(runner?.marketBook?.availableToBack?.size ) :'')}}</span>
                                      </button>
                                      <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                                          fxFlex="20%" *ngIf="runner?.marketBook?.availableToBack?.length == 0">
                                          <span>-</span>
                                          <span>-</span>
                                      </button>
  
                                      <div class="suspend" *ngIf="runner?.marketBook?.statusLabel=='Ball Running'">
                                          <div class="suspend-status">Ball runnig</div>
                                      </div>
                                      <div class="suspend" *ngIf="runner?.marketBook?.statusLabel=='SUSPENDED'">
                                          <div class="suspend-status">SUSPENDED</div>
                                      </div>
  
                                  </div>
  
                              </mat-expansion-panel>
  
                          </ng-container> -->
                            </mat-accordion>
                        </mat-tab>
                        <!-- 2nd tab -->
                        <mat-tab label="MATCHED BET ({{
                  allBetDataLength == '0' ? '0' : allBetDataLength
                }})">
                            <table class="bet_placed" *ngIf="allBetDataLength != 0">
                                <tr style="text-align: right">
                                    <th>Btype</th>
                                    <th>Nation</th>
                                    <th class="right-text">Odds</th>
                                    <th class="right-text">Amount</th>
                                </tr>
                                <ng-container *ngFor="let bet of allBetData">
                                    <!-- matchodds/bookmaker -->

                                    <tr [ngClass]="{
                        lay: bet?.type == 'Lay',
                        back: bet?.type == 'Back'
                      }">
                                        <td>
                                            <span *ngIf="
                            bet?.marketType === 'Special';
                            else fancy_match_type
                          ">bookmaker</span>
                                            <ng-template #fancy_match_type>{{
                                                bet?.marketType
                                                }}</ng-template>
                                        </td>
                                        <!-- matchOdds/bookmaker -->
                                        <ng-container *ngIf="
                          bet?.marketType === 'MATCH_ODDS' ||
                            bet?.marketType === 'Special';
                          else else_session_block
                        ">
                                            <td>{{ bet?.selectionName }}</td>
                                            <td class="right-text">
                                                <span *ngIf="
                              bet.marketType === 'Special';
                              else rate_matchOdds
                            ">{{ bet.rate * 100 - 100 | number : "0.0-2" }}</span>
                                                <ng-template #rate_matchOdds>{{
                                                    bet?.rate | number : "0.0-2"
                                                    }}</ng-template>
                                            </td>
                                        </ng-container>

                                        <!-- session -->
                                        <ng-template #else_session_block>
                                            <td>
                                                {{ bet?.marketName }} /
                                                {{ bet?.rate * 100 | number : ".0-2" }}
                                            </td>
                                            <td class="right-text">{{ bet?.selectionName }}</td>
                                        </ng-template>
                                        <!-- amount -->
                                        <td class="right-text">{{ bet?.stake }}</td>
                                    </tr>
                                </ng-container>
                            </table>
                            <div class="text-warning" style="color: black !important" *ngIf="allBetDataLength == 0">
                                No Bet Found
                            </div>
                        </mat-tab>
                        <!-- 2ndtab end -->
                    </mat-tab-group>
                </mat-card>

                <!-- paisaexch -->
                <ng-container *ngIf="page_type === 'paisaexch'">
                    <app-p-match-details></app-p-match-details>
                    <app-p-footer></app-p-footer>
                </ng-container>
            </ng-container>
            <!--///////////////Mobile end/////////////////////////-->
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<app-b-match-details class="betHonk_details" *ngIf="page_type === 'betHonk'"></app-b-match-details>

<!-- sessionBook bets -->
<ng-template #sessionBook>
    <div class="modal-header model_header">
        <h4 class="modal-title">Run Position</h4>
        <a type="button" class="close" data-dismiss="modal">
            <mat-icon (click)="modalRef.hide()" style="color: white">close</mat-icon>
        </a>
    </div>

    <div class="modal-body" style="background-color: white">
        <div class="table-div modeltable">
            <table class="table table-bordered">
                <tr>
                    <th class="text-center" align="center">Run</th>
                    <th class="text-right" align="right">Amount</th>
                </tr>
                <tr *ngFor="let l of sessionrunnerProfit | keyvalue" [ngClass]="l.value > 0 ? 'back' : 'lay'">
                    <td align="center">{{ l.key }}</td>
                    <td align="right">{{ l.value }}</td>
                </tr>
            </table>
            <button style="float: right" class="button-button3" (click)="modalRef.hide()">
                Close
            </button>
        </div>
    </div>
</ng-template>

<!-- bet_place_model -->
<ng-template #betPlace>
    <div class="modal-content custom-modal-main">
        <div class="newmatchpopup" [ngClass]="arrayObj.is_back == 1 ? 'back' : 'lay'">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="bet-slip-header">
                <span class="bet-slip-title">Placebet</span>
                <div class="close-btn" style="color: black" data-dismiss="modal" (click)="modalRef.hide()">
                    <mat-icon>close</mat-icon>
                </div>
            </div>
            <div fxlayout="column" fxlayoutgap="5px" fxlayoutalign="start" class="bet-slip-body"
                ng-reflect-fx-layout="column" ng-reflect-fx-layout-align="start" ng-reflect-fx-layout-gap="5px" style="
            flex-direction: column;
            box-sizing: border-box;
            display: flex;
            place-content: stretch flex-start;
            align-items: stretch;
          ">
                <div class="row title-second" style="
              background-color: transparent;
              color: rgb(33, 37, 41);
              margin-bottom: 5px;
              flex-wrap: inherit;
            ">
                    <div class="col-7 p-0 text-left">
                        <span class="text-center"><span *ngIf="arrayObj.is_fancy == 0; else fancy_name">{{
                                odds_bookmaker_db.bet.selectionName
                                }}</span><ng-template #fancy_name>{{
                                session_db.bet.marketName
                                }}</ng-template></span>
                    </div>
                    <div class="col-5 p-0 text-center">
                        <div class="input-group">
                            <div class="numbers">
                                <span class="minus">-</span>
                                <input type="text" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" min="0" step="0.01"
                                    id="ShowBetPrice" name="quant[2]" readonly=""
                                    class="calProfitLoss odds-input form-control CommanBtn"
                                    [(ngModel)]="arrayObj.odds" />
                                <span class="plus">+</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxlayout="row" fxlayoutgap="10px" ng-reflect-fx-layout="row" ng-reflect-fx-layout-gap="10px" style="
              margin-bottom: 5px;
              flex-direction: row;
              box-sizing: border-box;
              display: flex;
            ">
                    <div class="col-4 p-0" style="margin-right: 10px">
                        <input type="number" min="0" id="stakeValue" pattern="[0-9]*" step="1" placeholder="0"
                            class="form-control calProfitLoss stake-input f0orm-control CommanBtn"
                            ng-reflect-pattern="[0-9]*" ng-reflect-model="0" (input)="
                  calculateP_lOnStackOnInput(
                    arrayObj.stake,
                    arrayObj.stake,
                    arrayObj.is_back,
                    arrayObj
                  )
                " [(ngModel)]="arrayObj.stake < 0 ? '0' : arrayObj.stake" />
                    </div>
                    <div class="col-4 p-0" style="flex-direction: row; box-sizing: border-box; display: flex">
                        <div fxflex="100%" fxlayout="column" style="
                  display: flex;
                  flex-direction: column;
                  box-sizing: border-box;
                  flex: 1 1 100%;
                  max-width: 100%;
                " ng-reflect-fx-layout="column" ng-reflect-fx-flex="100%">
                            <button type="button" class="btn btn-outline-primary btn-primary btnblue btn-sm rounded"
                                style="height: 29px" [disabled]="arrayObj.stake === 0 || betloder"
                                (click)="saveFancyBet(arrayObj)" *ngIf="arrayObj.is_fancy == 1">
                                Place Bet
                            </button>
                            <button type="button" class="btn btn-outline-primary btn-primary btnblue btn-sm rounded"
                                style="height: 29px" [disabled]="arrayObj.stake === 0 || betloder"
                                (click)="saveBet(arrayObj)" *ngIf="arrayObj.is_fancy == 0">
                                Place Bet
                            </button>
                        </div>
                    </div>
                </div>

                <!-- stake_value -->
                <div class="betslip-button text-center" style="margin-bottom: 5px">
                    <div style="display: flex; width: 100%; vertical-align: middle">
                        <div class="scrollmenu">
                            <div class="tab-btn-card" *ngFor="let stake of userMatchStack; let i = index" (click)="
                    calculateP_lOnStack(
                      arrayObj.odds * arrayObj.stake - arrayObj.stake,
                      stake.price,
                      arrayObj.is_back,
                      arrayObj
                    )
                  ">
                                {{ stake.label }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- matchodds_bookmaker_profit_loss -->
                <div *ngIf="arrayObj.is_session_fancy == 'N'" fxlayout="row" fxlayoutalign="space-between center"
                    fxlayoutgap="10px" class="form-list" ng-reflect-fx-layout="row"
                    ng-reflect-fx-layout-align="space-between center" ng-reflect-fx-layout-gap="10px" style="
              flex-direction: row;
              box-sizing: border-box;
              display: flex;
              place-content: center space-between;
              align-items: center;
              margin-bottom: 5px;
            ">
                    <div class="stack_input_field" style="margin-right: 10px">
                        <label class="form-label">Profit</label>
                        <span id="profitData" class="profit" style="color: green">{{
                            arrayObj.stake <= "0" ? "0" : arrayObj.is_back==0 ? arrayObj.stake : arrayObj.odds *
                                arrayObj.stake - arrayObj.stake=="NaN" ? 0 : (arrayObj.odds * arrayObj.stake -
                                arrayObj.stake).toFixed(2) }}</span>
                    </div>
                    <div class="stack_input_field">
                        <label class="form-label">Loss</label>
                        <span id="profitData" class="profit" style="color: red">-{{
                            arrayObj.stake <= "0" ? "0" : arrayObj.is_back==0 ? arrayObj.odds * arrayObj.stake -
                                arrayObj.stake=="NaN" ? 0 : (arrayObj.odds * arrayObj.stake - arrayObj.stake).toFixed( 2
                                ) : arrayObj.stake }}</span>
                    </div>
                </div>
                <!-- fancy_profit_loss -->
                <div *ngIf="arrayObj.is_session_fancy == 'Y'" fxlayout="row" fxlayoutalign="space-between center"
                    fxlayoutgap="10px" class="form-list" ng-reflect-fx-layout="row"
                    ng-reflect-fx-layout-align="space-between center" ng-reflect-fx-layout-gap="10px" style="
              flex-direction: row;
              box-sizing: border-box;
              display: flex;
              place-content: center space-between;
              align-items: center;
              margin-bottom: 5px;
            ">
                    <div class="stack_input_field" style="margin-right: 10px">
                        <label class="form-label">Profit</label>
                        <span id="profitData" class="profit" style="color: green">{{
                            arrayObj.is_back == 0
                            ? arrayObj.stake
                            : ((arrayObj.size * arrayObj.stake) / 100).toFixed(2)
                            }}</span>
                    </div>
                    <div class="stack_input_field">
                        <label class="form-label">Loss</label>
                        <span id="profitData" class="profit" style="color: red">-{{
                            arrayObj.is_back == 0
                            ? ((arrayObj.size * arrayObj.stake) / 100).toFixed(2)
                            : arrayObj.stake
                            }}</span>
                    </div>
                </div>
                <div fxlayout="row" fxlayoutalign="space-around center" fxlayoutgap="10px" ng-reflect-fx-layout="row"
                    ng-reflect-fx-layout-align="space-around center" ng-reflect-fx-layout-gap="10px" style="
              flex-direction: row;
              box-sizing: border-box;
              display: flex;
              place-content: center space-around;
              align-items: center;
            "></div>
            </div>
        </div>
    </div>
</ng-template>

<!-- match_rules -->
<ng-template #match_rules>
    <div class="modal-header model_header bet-slip-header">
        <h4 class="modal-title" style="background-color: #0188cc !important">
            <span class="text-white">{{ rule_type }}</span>
        </h4>
        <a type="button" class="close" data-dismiss="modal">
            <mat-icon (click)="modalRef.hide()" style="color: white">close</mat-icon>
        </a>
    </div>

    <div class="modal-body" style="background-color: white">
        <ng-container *ngIf="rule_type === 'Match Rules'">
            <p>
                TENNIS Match Odds :- If 1st set has been not completed at the time of
                the retirement or disqualification, then all bets relating to that
                individual match will be void. FOOTBALL Match Odds :- All bets apply to
                the relevant full 'regular time' period including stoppage time. Any
                extra-time and/or penalty shoot-out is not included. For the
                cancellation of a goal, due to VAR, bets matched between the time of the
                goal being scored and the time at which the video assistant referee
                finishes the review will be voided. For the cancellation of a red card,
                due to VAR, bets matched after the time at which the video assistant
                referee commences the review will be voided. FOOTBALL Under_Over Goals
                :- In the event of a match starting but not being completed, all bets
                will be void, unless the specific market outcome is already determined
            </p>
        </ng-container>
        <ng-container *ngIf="rule_type === 'Bookmaker Rules'">
            <p>
                Due to any reason any team will be getting advantage or disadvantage we
                are not concerned. Company reserves the right to suspend/void any
                id/bets if the same is found to be illegitimate. For example incase of
                vpn/robot-use/multiple entry from same IP/ multiple bets at the same
                time (Punching) and others. Note : only winning bets will be voided. We
                will simply compare both teams 25 overs score higher score team will be
                declared winner in ODI (25 over comparison) We will simply compare both
                teams 10 overs higher score team will be declared winner in T20 matches
                (10 over comparison) Any query about the result or rates should be
                contacted within 7 days of the specific event, the same will not be
                considered valid post 7 days from the event. If two team ends up with
                equal points, then result will be given based on the official point
                table Tennis:- Advance fancy market If the second set is not completed
                all bets regarding this market will be voided If a player retires after
                completion of second set, then the market will be settled as three sets
                Virtual Cricket At any situation if the video gets interrupted/stopped
                then the same cannot be continued due to any technical issues bookmaker
                market will be voided
            </p>
        </ng-container>

        <ng-container *ngIf="rule_type === 'Fancy Rules'">
            <p>
                1. All fancy bets will be validated when match has been tied. 2. All
                advance fancy will be suspended before toss or weather condition. 3. In
                case technical error or any circumstances any fancy is suspended and
                does not resume result will be given all previous bets will be valid
                (based on haar/jeet). 4. If any case wrong rate has been given in fancy
                that particular bets will be cancelled. 5. In any circumstances
                management decision will be final related to all exchange items. Our
                scorecard will be considered as valid if there is any mismatch in online
                portal 6. In case customer make bets in wrong fancy we are not liable to
                delete, no changes will be made and bets will be consider as confirm
                bet. 7. Due to any technical error market is open and result has came
                all bets after result will be deleted. 8. Manual bets are not accepted
                in our exchange 9.Our exchange will provide 5 second delay in our tv.
                10. Company reserves the right to suspend/void any id/bets if the same
                is found to be illegitimate. For example incase of
                vpn/robot-use/multiple entry from same IP/ multiple bets at same time
                (Punching) and others. Note : only winning bets will be voided, For
                example: If we find such entries (above mentioned) from any id and their
                bets are (200000 lay in a 6 over session for the rate 40 and 200000 back
                for the rate of 48) and the actual score is 38, bets of 40 lay will be
                voided and the bets for 48 back will be considered valid. 11. Company
                reserves the right to void any bets (only winning bets) of any event at
                any point of the match if the company believes there is any
                cheating/wrong doing in that particular event by the players (either
                batsman/bowler) 12. Once our exchange give username and password it is
                your responsibility to change a password. 13. Penalty runs will not be
                counted in any fancy. 14. Warning:- live scores and other data on this
                site is sourced from third party feeds and may be subject to time delays
                and/or be inaccurate. If you rely on this data to place bets, you do so
                at your own risk. Our exchange does not accept responsibility for loss
                suffered as a result of reliance on this data. 15.Traders will be block
                the user ID if find any misinterpret activities, No queries accept
                regarding. 16. Our exchange is not responsible for misuse of client id.
                Test 1 Session:- 1.1 Complete session valid in test. 1.2 Middle session
                and Session is not completed due to Innings declared or all out so that
                particular over considered as completed and remaining over counted in
                next team Innings for ex:- In case of Innings declared or all out In
                131.5th over Considered as 132 over completed remaining 1 over counted
                for 133 over middle session and 3 over counted for 135 over session from
                next team Innings and One over session and Only over session is not
                completed due to innings declared so that Particular over session bets
                will be deleted and all out considered as valid for ex:- In case of
                Innings declared In 131.5th over so 132 over will be deleted and if all
                out then 132 over and Only 132 over will be Valid. 1.3 1st day 1st
                session run minimum 25 over will be played then result is given
                otherwise 1st day 1st session will be deleted. 1.4 1st day 2nd session
                run minimum 25 over will be played then result is given otherwise 1st
                day 2nd session will be deleted. 1.5 1st day total run minimum 80 over
                will be played then result is given otherwise 1st day total run will be
                deleted. 1.6 Test match both advance session is valid. 2 Test lambi/
                Inning run:- 2.1 Mandatory 70 over played in test lambi paari/ Innings
                run. If any team all-out or declaration lambi paari/ innings run is
                valid. 2.2 In case due to weather situation match has been stopped all
                lambi trades will be deleted. 2.3 In test both lambi paari / inning run
                is valid in advance fancy. 3 Test batsman:- 3.1 In case batsmen is
                injured he/she is made 34 runs the result will be given 34 runs. 3.2
                Batsman 50/100 run if batsman is injured or declaration the result will
                be given on particular run. 3.3 In next men out fancy if player is
                injured particular fancy will be deleted. 3.4 In advance fancy opening
                batsmen is only valid if same batsmen came in opening the fancy will be
                valid in case one batsmen is changed that particular player fancy will
                be deleted. 3.5 Test match both advance fancy batsmen run is valid. 4
                Test partnership:- 4.1 In partnership one batsman is injured partnership
                is continued in next batsman. 4.2 Partnership and player runs due to
                weather condition or match abandoned the result will be given as per
                score. 4.3 Advance partnership is valid in case both players are
                different or same. 4.4 Test match both advance fancy partnership is
                valid. 5 Other fancy advance (test):- 5.1 Four, sixes, wide, wicket,
                extra run, total run, highest over and top batsmen is valid only if 300
                overs has been played or the match has been won by any team otherwise
                all these fancy will be deleted. Additionally all events are valid only
                for 1st innings( this is applicable to all individual team events also)
                2 Odi rule:- Session:- Match 1st over run advance fancy only 1st innings
                run will be counted. Complete session is valid in case due to rain or
                match abandoned particular session will be deleted. For example:- 35
                over run team a is playing any case team A is all-out in 33 over team a
                has made 150 run the session result is validated on particular run.
                Advance session is valid in only 1st innings. 50 over runs:- In case 50
                over is not completed all bet will be deleted due to weather or any
                condition. Advance 50 over runs is valid in only 1st innings. Odi
                batsman runs:- In case batsman is injured he/she is made 34 runs the
                result will be given 34 runs. In next men out fancy if player is injured
                particular fancy will be deleted. In advance fancy opening batsmen is
                only valid if same batsmen came in opening the fancy will be valid in
                case one batsmen is changed that particular player fancy will be
                deleted. Odi partnership runs:- In partnership one batsman is injured
                partnership is continued in next batsman. Advance partnership is valid
                in case both players are different or same. Both team advance
                partnerships are valid in particular match. Other fancy:- Four, sixes,
                wide, wicket, extra run, total run, highest over ,top batsman,maiden
                over,caught-out,no-ball,run-out,fifty and century are valid only match
                has been completed in case due to rain over has been reduced all other
                fancy will be deleted. T20:- Session:- Match 1st over run advance fancy
                only 1st innings run will be counted. Complete session is valid in case
                due to rain or match abandoned particular session will be deleted. For
                example :- 15 over run team a is playing any case team a is all-out in
                13 over team A has made 100 run the session result is validated on
                particular run. Advance session is valid in only 1st innings. 20 over
                runs:- Advance 20 over run is valid only in 1st innings. 20 over run
                will not be considered as valid if 20 overs is not completed due to any
                situation T20 batsman runs:- In case batsman is injured he/she is made
                34 runs the result will be given 34 runs. In next men out fancy if
                player is injured particular fancy will be deleted. In advance fancy
                opening batsmen is only valid if same batsmen came in opening the fancy
                will be valid in case one batsmen is changed that particular player
                fancy will be deleted. T20 partnership runs:- In partnership one batsman
                is injured partnership is continued in next batsman. Advance partnership
                is valid in case both players are different or same. Both team advance
                partnerships are valid in particular match. 1st 2 & 3 Wickets runs:-
                T20/ODI Advance event is valid in only 1st Innings. If over reduced due
                to rain or weather condition or match abandoned the result will be given
                as per score. Other fancy:- T-20 ,one day and test match in case current
                innings player and partnership are running in between match has been
                called off or abandoned that situation all current player and
                partnership results are valid. Four, sixes, wide, wicket, extra run,
                total run, highest over and top batsman,maiden
                over,caught-out,no-ball,run-out,fifty and century are valid only match
                has been completed in case due to rain over has been reduced all other
                fancy will be deleted. 1st 6 over dot ball and 20 over dot ball fancy
                only valid is 1st innings. 1st wicket lost to any team balls meaning
                that any team 1st wicket fall down in how many balls that particular
                fancy at least minimum one ball have to be played otherwise bets will be
                deleted. 1st wicket lost to any team fancy valid both innings. How many
                balls for 50 runs any team meaning that any team achieved 50 runs in how
                many balls that particular fancy at least one ball have to be played
                otherwise that fancy bets will be deleted. How many balls for 50 runs
                fancy any team only first inning valid. 1st 6 inning boundaries runs any
                team fancy will be counting only according to run scored fours and sixes
                at least 6 over must be played otherwise that fancy will be deleted. 1st
                inning 6 over boundaries runs any team run like wide ,no-ball ,leg-byes
                ,byes and over throw runs are not counted this fancy. How many balls
                face any batsman meaning that any batsman how many balls he/she played
                that particular fancy at least one ball have to be played otherwise that
                fancy bets will be deleted. How many balls face by any batsman both
                innings valid. Lowest scoring over will be considered valid only if the
                over is completed fully (all six deliveries has to be bowled) Concussion
                in Test:- All bets of one over session will be deleted in test scenario,
                in case session is incomplete. For example innings declared or match
                suspended to bad light or any other conditions. 1. All bets will be
                considered as valid if a player has been replaced under concussion
                substitute, result will be given for the runs scored by the mentioned
                player. For example DM Bravo gets retired hurt at 23 runs, then result
                will be given for 23. 2. Bets of both the player will be valid under
                concussion substitute. Total Match- Events (test):- Minimum of 300 overs
                to be bowled in the entire test match, otherwise all bets related to the
                particular event will get void. For example, Total match caught outs
                will be valid only if 300 overs been bowled in the particular test match
                Limited over events-Test:- This event will be considered valid only if
                the number of overs defined on the particular event has been bowled,
                otherwise all bets related to this event will get void. For example 0-25
                over events will be valid only if 25 overs has been bowled, else the
                same will not be considered as valid If the team gets all out prior to
                any of the defined overs, then balance overs will be counted in next
                innings. For example if the team gets all out in 23.1 over the same will
                be considered as 24 overs and the balance overs will be counted from
                next innings. Bowler Wicket event's- Test:- Minimum of one legal over
                (one complete over) has to be bowled by the bowler mentioned in the
                event, else the same will not be considered as valid Bowler over events-
                Test:- The mentioned bowler has to complete the defined number of overs,
                else the bets related to that particular event will get void. For
                example if the mentioned bowler has bowled 8 overs, then 5 over run of
                that particular bowler will be considered as valid and the 10 over run
                will get void Player ball event's- Test:- This event will be considered
                valid only if the defined number of runs made by the mentioned player,
                else the result will be considered as 0 (zero) balls For example if Root
                makes 20 runs in 60 balls and gets out on 22 runs, result for 20 runs
                will be 60 balls and the result for balls required for 25 run Root will
                be considered as 0 (Zero) and the same will be given as result Limited
                over events-ODI:- This event will be considered valid only if the number
                of overs defined on the particular event has been bowled, otherwise all
                bets related to this event will get void. 0-50 over events will be valid
                only if 50 over completed, if the team batting first get all out prior
                to 50 over the balance over will be counted from second innings. For
                example if team batting first gets all out in 35 over balance 15 over
                will be counted from second innings, the same applies for all events if
                team gets all out before the defined number of overs The events which
                remains incomplete will be voided if over gets reduced in the match due
                to any situation, for example if match interrupted in 15 overs due to
                rain/badlight and post this over gets reduced. Events for 0-10 will be
                valid, all other events related to this type will get deleted. This
                events will be valid only if the defined number of over is completed.
                For example team batting first gets all out in 29.4 over then the same
                will be considered as 30 over, the team batting second must complete 20
                overs only then 0-50 over events will be considered as valid. In case
                team batting second gets all out in 19.4 over then 0-50 over event will
                not be considered as valid, This same is valid for 1st Innings only.
                Bowler event- ODI:- The mentioned bowler has to complete the defined
                number of overs, else the bets related to that particular event will get
                void. For example if the mentioned bowler has bowled 8 overs, then 5
                over run of that particular bowler will be considered as valid and the
                10 over run will get void Both innings are valid Other event:- T20 The
                events for 1-10 over and 11-20 over will be considered valid only if the
                number of over mentioned has been played completely. However if the over
                got reduced before the particular event then the same will be voided, if
                the team batting first get all out prior to 20 over the balance over
                will be counted from second innings. For example if team batting first
                gets all out in 17 over balance 3 over will be counted from second
                innings and that 3 over all events are counted. This same is valid for
                1st Innings only. If over got reduced in between any running event, then
                the same will be considered valid and the rest will be voided. For
                example.., match started and due to rain/bad light or any other
                situation match got interrupted at 4 over and later over got reduced.
                Then events for 1-10 is valid rest all will be voided Bowler Session:
                Bowler session advance events only valid for 1st inning. This event is
                valid only if the bowler has completed his maximum quota of overs, else
                the same will be voided. However if the match has resulted and the
                particular bowler has already started bowling his final over then result
                will be given even if he haven't completed the over. For example B Kumar
                is bowling his final over and at 3.4 the match has resulted then result
                will be given for B Kumar over runs Incase of DLS, the over got reduced
                then the bowler who has already bowled his maximum quota of over that
                result will be considered as valid and the rest will be voided Dot ball
                Event: Only No run will count as dot ball. If wicket means that will not
                count as dot ball. Boundary on Match 1st Free hit Both innings are valid
                Boundary hit on Free hit only be considered as valid Bets will be
                deleted if there is no Free hit in the mentioned match Boundary by bat
                will be considered as valid Boundaries by Player Both Four and six are
                valid No Boundaries Event: Both Four and Six are valid Batsman bat
                boundaries only considered as valid Free hit boundaries also valid Bets
                will be voided if that particular ball not completed Result will be
                Given 0 or 4 (No or Yes). For Example batsman hit boundary in particular
                ball means Result is 0 otherwise Result is 4. Any query regarding result
                or rate has to be contacted within 7 days from the event, query after 7
                days from the event will not be considered as valid Virtual Cricket
                Scorecard available on the video will be considered as valid. At any
                situation, if there is a difference between the scorecard in the website
                and the scorecard available on video. Score card available on video will
                be valid In case of any technical issues the video gets
                interrupted/stopped and the same cannot be continued, the existing
                markets will be voided. However the markets which are already
                finished/settled will remain valid. CPL:- If CPL fixture 0f 33 matches
                gets reduced due to any reason, then all the special fancies will be
                voided (Match abandoned due to rain/bad light will not be considered in
                this) Fancy based on all individual teams are valid only for league
                stage Total 1st over runs: Average 6 runs will be given in case match
                abandoned or over reduced Total fours: Average 22 fours will be given in
                case match abandoned or over reduced Total sixes: Average 13 sixes will
                be given in case match abandoned or over reduced Total Wickets - Average
                will 13 Wickets be given in case match abandoned or over reduced Total
                Wides - Average 10 wides will be given in case match abandoned or over
                reduced Total Extras - Average 18 extras will be given in case match
                abandoned or over reduced Total No ball - Average 1 no ball will be
                given in case match abandoned or over reduced Total Fifties - Average 1
                fifties will be given in case match abandoned or over reduced Total
                Caught outs: Average 9 caught out will be given in case match abandoned
                or over reduced At any situation if result is given for any particular
                event based on the rates given for the same, then the particular result
                will be considered valid, similarly if the tournament gets canceled due
                to any reason the previously given result will be considered valid
                Management decision will be final Highest innings run - Only first
                innings is valid Lowest innings run - Only first innings is valid
                Highest over run: Both innings are valid Highest 1st over run in
                individual match: Both innings are valid, however for CPL we have
                created the fancy for 1st innings only Highest Fours in individual
                match: Both innings are valid Highest Sixes in individual match: Both
                innings are valid Highest Extras in individual match: Both innings are
                valid Highest Wicket in individual match: Both innings are valid Super
                over will not be included Barbados Tridents Opening partnership run:
                Average 24 runs will be given in case match abandoned or over reduced
                First 6 over run: Average 45 run will be given in case match abandoned
                or over reduced St Kitts and Nevis Patriots Opening partnership run:
                Average 25 runs will be given in case match abandoned or over reduced
                First 6 over run: Average 45 run will be given in case match abandoned
                or over reduced Trinbago Knight Riders Opening partnership run: Average
                22 runs will be given in case match abandoned or over reduced First 6
                over run: Average 46 run will be given in case match abandoned or over
                reduced Guyana Amazon Warriors Opening partnership run: Average 23 runs
                will be given in case match abandoned or over reduced First 6 over run:
                Average 44 run will be given in case match abandoned or over reduced St
                Lucia Zouks Opening partnership run: Average 22 runs will be given in
                case match abandoned or over reduced First 6 over run: Average 43 run
                will be given in case match abandoned or over reduced Jamaica Tallawahs
                Opening partnership run: Average 24 runs will be given in case match
                abandoned or over reduced First 6 over run: Average 46 run will be given
                in case match abandoned or over reduced Tour Special Events Australia
                tour of Sri Lanka, 2022 If first match of tour (T20 or ODI) cancelled or
                over reduce in first match, then all special fancy events will be
                deleted If First match played completely and next match gets over
                reduced or cancelled, then that match bets all bets will be deleted and
                first match bets will be valid and average will count in other bets Ex.
                : First match total 4's is 20 and second match over reduced so bets
                after first match upto second match started that all deleted and for
                other bets average counted total 4's is 24 and third match total 4's is
                26, So Result of Total 4's is 70 T20 : Total Matches 1st over runs :
                Average 6 runs will be given in case match abandoned or over reduced
                (only 1st innings valid) Total Matches 1st 6 over runs : Average 45 runs
                will be given in case match abandoned or over reduced (Only 1st Innings
                valid) Total fours: Average 24 fours will be given in case match
                abandoned or over reduced Total sixes: Average 9 sixes will be given in
                case match abandoned or over reduced Total Boundaries: Average 33
                Boundaries will be given in case match abandoned or over reduced Total
                Fifties - Average 2 Fifties will be given in case match abandoned or
                over reduced Total Wickets - Average 12 Wickets will be given in case
                match abandoned or over reduced Total Wides - Average 8 Wides will be
                given in case match abandoned or over reduced Total Extras - Average 15
                Extras will be given in case match abandoned or over reduced Total
                Caught outs: Average 8 Caught out will be given in case match abandoned
                or over reduced Total Bowled:- Average 2 Bowled out will be given in
                case match abandoned or over reduced Total LBW:- Average 2 LBW will be
                given in case match abandoned or over reduced Total Run out:- Average 1
                Run out will be given in case match abandoned or over reduced At any
                situation if result is given for any particular event based on the rates
                given for the same, then the particular result will be considered valid,
                similarly if the tournament gets canceled due to any reason the
                previously given result will be considered valid Most runs given by
                Bowler in an Inning of Tour : Maximum How much Runs conceded by a
                individual Bowler in an Innings. Ex : For T20I How much runs conceded by
                a bowler in his 4 overs Quota. Highest total runs in individual match of
                IPL : Maximum Total runs of both Teams in individual match. Highest
                innings run - Only first inning is valid Highest Partnership - Both
                innings are valid Highest 1st over run of individual match: only first
                inning is valid Highest Fours of individual match: Both innings are
                valid Highest Sixes of individual match: Both innings are valid Highest
                Extras of individual match: Both innings are valid Highest over run:
                Both innings are valid Top Batsman Runs in Tour : Most runs by an
                Individual Player in any Individual matches in ODI/T20I Format of Tour
                Highest Wickets by Bowler in Tour : Most Wickets taken by Individual
                Player in Overall ODI/T20I Format of Tour Highest Over runs in Tour :
                Most Runs Scored in Any Single Over of any Individual Match in Overall
                ODI/T20I Format of Tour Player Total Runs : Total Runs Scored by an
                Individual Player in Full ODI/T20I Format of Tour Player Total 4s :
                Total 4s Hitted by an Individual Player in Full ODI/T20I Format of Tour
                Player Total 6s : Total 6s Hitted by an Individual Player in Full
                ODI/T20I Format of Tour Player Boundaries : Total Boundaries Hitted by
                an Individual Player in Full ODI/T20I Format of Tour For Player based
                Events, If any Player not in playing 11 suppose First match any player
                played completely but if same player not available in next match, then
                that match bets all bets will be deleted and first match bets will be
                valid and average will count in other bets If any one match not in
                Playing 11 means Average Given for that match Average For Players: A
                Finch Total Runs, Boundaries, 4s and 6s : 26,4,3 & 1. D Warner Total
                Runs, Boundaries, 4s and 6s : 28,5,4& 1. P Nissanka Total Runs,
                Boundaries, 4s and 6s : 22,3,2 & 1. D Gunathilaka Total Runs,
                Boundaries, 4s and 6s : 23,3,2 & 1. J Hazlewood,M Starc,D Chameera & M
                Theekshana : 2 wkts Average given if player not in playing 11.
            </p>
        </ng-container>
    </div>
</ng-template>