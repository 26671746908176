<div style="background: black; padding-bottom: 77vh">
  <div
    class="row fixed-top"
    style="background: #00897b; padding: 12px 5px 7px 22px"
  >
    <div
      class="col-2"
      [routerLink]="['/wallet-home']"
      style="padding: 8px 0px 8px 15px"
    >
      <i class="fa fa-arrow-left" style="color: white; font-size: 19px"></i>
    </div>
    <div class="col-8" style="text-align: center">
      <h1 style="color: white">Passbook</h1>
    </div>
    <div
      class="col-2"
      [routerLink]="['/notifications']"
      style="padding: 8px 0px 8px 15px"
    >
      <i class="far fa-bell" style="font-size: 20px; color: white"></i>
    </div>
  </div>

  <div class="row" style="margin: 19px 0px 0px 0px">
    <div class="col-5" style="padding-bottom: 21px; margin: 76px 0px 0px 0px">
      <input
        type="date"
        style="width: 100% !important; padding: 8px !important"
        [(ngModel)]="DateFrom"
        (change)="getTransactionsByDate()"
      />
    </div>
    <div class="col-2" style="text-align: center; margin: 76px 0px 0px 0px">
      <h4 style="color: white; margin: 10px 0px 0px 0px">To</h4>
    </div>
    <div class="col-5" style="padding-bottom: 21px; margin: 76px 0px 0px 0px">
      <input
        type="date"
        style="width: 100% !important; padding: 8px !important"
        [(ngModel)]="DateTo"
        (change)="getTransactionsByDate()"
      />
    </div>
  </div>
  <div class="container">
    <div class="row" style="justify-content: center; margin: 1px 0px 0px 0px">
      <div
        class="col-12"
        style="
          background: #2b2a2a;
          padding: 10px 15px;
          border-radius: 7px;
          margin: 12px 0px 0px 0px;
          border: 1px solid #d2d2d2;
        "
        (click)="getTransaction(x._id)"
        *ngFor="let x of transactions"
      >
        <div class="row">
          <div class="col-3">
            <img
              class="rounded-circle"
              style="width: 53px; height: 60px"
              src="https://tse1.mm.bing.net/th?id=OIP.wXfLkrXiwn6znutynk4LbgHaHa&pid=Api&P=0&h=180"
              alt=""
            />
          </div>
          <div class="col-6" style="color: white">
            <p *ngIf="x.type == 'Deposit'" style="font-size: 13px">
              {{ x.type }} to {{ x.to }}
            </p>
            <p *ngIf="x.type == 'Withdrawal'" style="font-size: 13px">
              {{ x.type }} from {{ x.to }}
            </p>
            <p style="margin: -10px 0px 0px 0px">{{ x.updatedAt }}</p>
            <p
              [ngClass]="{
                pending: x.status == 'Pending',
                approved: x.status == 'Approved',
                declined: x.status == 'Decline'
              }"
            >
              {{ x.status }}
            </p>
            <p
              style="margin: -10px 0px 0px 0px; color: #2fbbff"
              *ngIf="x.remarks"
            >
              Remarks : {{ x.remarks }}
            </p>
          </div>
          <div class="col-3" style="color: white; text-align: center">
            <p
              style="
                background: #5e5c5c;
                border-radius: 10px;
                margin: 10px 0px 0px 0px;
                padding: 5px 0px;
                font-size: 16px;
                letter-spacing: 1px;
              "
              [ngClass]="{
                pending: x.status == 'Pending',
                approved: x.status == 'Approved',
                declined: x.status == 'Decline'
              }"
            >
              {{ x.amount }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footerbar></app-footerbar>
