import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slotgame',
  templateUrl: './slotgame.component.html',
  styleUrls: ['./slotgame.component.scss']
})
export class SlotgameComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
