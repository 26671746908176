<!-- <app-header></app-header> -->
<!-- <div class="container-fluid" style="background: white;">
<div class="row" style="background: var(--theme1-bg);">
    <div class="col-2" style="text-align: center;padding: 11px 0px 0px 0px;color: white;"><mat-icon>
        arrow_back_ios</mat-icon></div>
    <div class="col-8" style="text-align: center;padding: 8px 0px 0px 0px;">
        <h2 style="color:white;letter-spacing: 1.4px;">Wallet</h2>
    </div>
</div>
<div class="row" style="margin: 20px 0px 0px 0px;background: #ffd000;padding: 14px 0px 7px 0px;border-radius: 6px;color: black;">
    <div class="col-4" style="text-align: center;">
       <h5 style="font-size: 16px"><i class="fas fa-home"></i>&nbsp; <b> Home</b></h5> 
    </div>
    <div class="col-4" style="text-align: center;">
        <h5 style="font-size: 16px"><i class="fas fa-list"></i>&nbsp; <b>Passbook</b></h5> 
     </div>
     <div class="col-4" style="text-align: center;">
        <h5 style="font-size: 16px"><i class="fas fa-user-plus"></i>&nbsp; <b>IDs</b></h5> 
     </div>
</div>

<div class="row" style="margin: 34px 0px 0px 0px;border: 0px;border-radius: 8px;padding: 17px 0px 17px 0px;background: #ffd000;">
    <div class="col-12" style="text-align: center;border-bottom: 1px solid white;"><h4 style="color: balck;"><b style="letter-spacing: 2px;"> WALLET &nbsp;BALANCE</b></h4></div>

    <div class="col-12" style="text-align: center;padding: 17px 0px 0px 0px;"><h4 style="color:#008d00"><i class="fas fa-wallet" style="font-size: 20px"></i> &nbsp;<b style="font-size: 21px">{{walletBalance}}</b></h4></div>

    <div class="col-12" style="text-align: center;"><h4 style="color: #dd4343;"><i class="fas fa-money-bill" style="font-size: 18px"></i>&nbsp; <b style="font-size: 20px">1000</b></h4></div>
</div>

<div class="row" style="margin: 37px 0px 0px 0px ;">

    <div class="col-6"><button class="btn-block" style="padding: 8px 0px 8px 0px;background: #1abe27;border: 0px;border-radius: 4px;color: white;" (click)="openDepo()"><b style="font-size: 15px;letter-spacing: 2px;font-weight: 550;">DEPOSIT &nbsp;<i class="fas fa-landmark"></i></b></button></div>

    <div class="col-6"><button class="btn-block btn-success" style="padding: 8px 0px 8px 0px;background: #ff3352;border: 0px;border-radius: 4px;color: white;" (click)="openWithdraw()"><b style="font-size: 15px;letter-spacing: 2px;font-weight: 550;">WITHDRAW &nbsp;<i class="fas fa-download"></i></b></button></div>
</div>
</div> -->

<div class="row" style="background: linear-gradient(340deg, #0f372e, #1f8b6c);padding: 14px 5px 14px 22px;">
    <div class="col-2" [routerLink]="['/home/inplay']" style="padding: 8px 0px 8px 15px;">
      <i class="fa fa-arrow-left" style="color: white;font-size: 20px;"></i>
    </div>
    <div class="col-8">
    </div>
    <div class="col-2" [routerLink]="['/notifications']" style="padding: 8px 0px 8px 15px;">
        <i class="far fa-bell" style="font-size: 20px;color: white;" ></i>
    </div>
    <div class="col-1"></div>
    <div class="col-12" style="margin: 18px 0px 50px 0px;">
       <div class="row">
        <div class="col-2"></div>
        <div class="col-2" (click)="openDepo()" style="text-align: center;padding: 25px 0px 25px 0px;border-radius: 5px 0px 0px 5px;margin: 10px 0px 13px 0px;background:#1e1d1d; color: white;letter-spacing: 1px;">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708l6-6z"/>
                <path fill-rule="evenodd" d="M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
              </svg><br> <h5> Deposit</h5>
        </div>
        <div class="col-4" style="text-align: center;padding: 25px 0px 25px 0px;border-radius: 5px;background: #1e1d1d; color: white;margin: -8px 0px 4px 0px">
           <h4 style="letter-spacing: 1px;">Wallet Balance <br><br>{{walletBalance | number : '0.0-2'}}</h4> 
        </div>
        <div class="col-2" style="text-align: center;padding: 25px 0px 25px 0px;border-radius: 0px 5px 5px 0px;margin: 10px 0px 13px 0px;background: #1e1d1d; color: white;letter-spacing: 1px;" (click)="openWithdraw()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                <path fill-rule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg> <br><h5>Withdraw</h5> 
        </div>
       </div>
    </div>
</div>
<div class="container-fluid" style="background: black;border-radius: 20px 20px 0px 0px;margin: -28px 0px 0px 0px;height: 70vh;">
    <div class="row"><div class="col-12" style="margin: 10px 0px 0px 0px;">
    
    
  <div id="demo" class="carousel slide my-2" data-ride="carousel">
   
    <div class="carousel-inner">
      <div class="carousel-item" [ngClass]="{'active': i == 0}" *ngFor="let x of banner;let i = index">
      <img src="https://adminsocket.diamond222.com/uploads/{{x.bannerImage}}" alt="{{x?.name}}" height="180">
      </div>
      
     
    <a class="carousel-control-prev" href="#demo" data-slide="prev">
      <span class="carousel-control-prev-icon"></span>
    </a>
    <a class="carousel-control-next" href="#demo" data-slide="next">
      <span class="carousel-control-next-icon"></span>
    </a>
  </div>
</div>
      
    

</div></div>
<div class="row" style="margin: 25px 0px 0px 0px;">
    <div class="col-12">
      <!-- <div id="demo" class="carousel slide my-2" data-ride="carousel">
   
        <div class="carousel-inner">
          <div class="carousel-item" [ngClass]="{'active': i == 0}" *ngFor="let x of banner;let i = index">
         
          </div>
          
         
        <a class="carousel-control-prev" href="#demo" data-slide="prev">
          <span class="carousel-control-prev-icon"></span>
        </a>
        <a class="carousel-control-next" href="#demo" data-slide="next">
          <span class="carousel-control-next-icon"></span>
        </a>
      </div>
    </div> -->
    </div>
</div>
<div class="row" style="justify-content: center;display: flex;margin: 40px 0px 0px 0px;">
    <div class="col-10" style="text-align: center;background: #1e1d1d;padding: 30px 0px 40px 0px;border-radius: 10px;">
        <div class="row" style="justify-content: center;display: flex;"><div class="col-10">
      <h5 style="padding: 10px 10px 10px 10px;color: white;letter-spacing: 1px;background: #42d342;display: initial;border-radius: 19px;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16" style="margin: -3px 0px 0px 0px;" >
        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
      </svg>&nbsp;<a href="https://web.whatsapp.com/send?text=www.google.com"  style="color:white"> Whatsapp Support </a></h5> <p style="margin: 15px 0px 0px 0px;color: white;">Get your queries,new updates and latest offers via whatsapp support.</p> </div></div>
    </div>
</div>

</div>
<div class="row"><div class="col-12"><app-footerbar></app-footerbar></div></div>
