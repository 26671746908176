
<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <app-header></app-header>
        <app-footer></app-footer>
        <div class="main-content" >
            <div class="d-flex" style="background-color: var(--theme2-bg); width: 100%;"> <span class="ml-2" style="padding: 6px;
                font-weight: 500; color: #fff !important;">Secure Auth Verification</span>
            </div>
            <div class="row-sm d-flex mt-2">
                <div class="col-sm-10"  style="padding: 3px 3px 0px 2px !important;">
                    <div class="title"><span style="color: black;">Secure Auth Verification Status:</span></div>
                </div>
                <div class="col-sm-2 col-btn-disabled text-right"  >
                    <div class="btn_div">
                        <button type="button" class="btn">Disabled</button>
                    </div>
                </div>
            </div>
            <div class="row-sm mt-2">
                <div class="col-sm-12 col-para">
                   <div>Please select below option to enable secure auth verification</div>
                     
                </div>
            </div>
            <div class="row-sm d-flex mt-1">
                <div class="col-sm-6 col-link text-right">
                    <div class="col-div-left"><button type="button" class="btn btn-outline-success" style="font-size: 8px !important;">Enable Using Mobile App</button></div>
                     
                </div>
                <div class="col-sm-6 col-link text-left">
                    <div class="col-div-right"> <button type="button" class="btn btn-outline-success" style="font-size: 8px !important;">Enable Using Telegram</button></div>
                      
                 </div>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>