<div style="background: black;padding-bottom: 72vh;">
<div class="row" style="background: #00897B;padding: 14px 5px 4px 22px;">
    <div class="col-2" [routerLink]="['/passbook']" style="padding: 8px 0px 8px 15px;">
      <i class="fa fa-arrow-left" style="color: white;font-size: 19px;"></i>
    </div>
    <div class="col-8" style="text-align: center;">
      <h1 style="color: white;">Transaction Detail</h1>
    </div>
    <div class="col-2" [routerLink]="['/notifications']" style="padding: 8px 0px 8px 15px;">
        <i class="far fa-bell" style="font-size: 20px;color: white;"></i>
    </div>
    </div>
   
    <div class="row" style="margin: 20px 0px 0px 0px;">
        <div class="col-12" >
            
              <div class="row" *ngFor="let x of Details" style="
              margin: 0;
             background:#343232 ;
              padding: 10px 2px 10px 2px;
             border-radius: 5px;">
             
                <div class="col-8" style="font-size: 15px;color: white;padding: 5px 5px 5px 16px;">
                    Name
                </div>
                <div class="col-4" style="text-align: center;font-size: 15px;color: white;padding: 5px;">
                    {{x?.name}}
                </div>
                <div  class="col-8" style="font-size: 15px;color: white;padding: 5px 5px 5px 16px;">
                    Payment Type
                </div>
                <div  class="col-4" style="text-align: center;font-size: 15px;color: white;padding: 5px;">
                    {{x?.type}}
                </div>
                <div *ngIf="x?.paymenttype == 'bank'"  class="col-8" style="font-size: 15px;color: white;padding: 5px 5px 5px 16px;">
                    Account No.
                </div>
                <div *ngIf="x?.paymenttype == 'bank'"  class="col-4" style="text-align: center;font-size: 15px;color: white;padding: 5px;">
                   {{x?.depositId.accnumber}}
                </div>
                <div *ngIf="x?.paymenttype == 'bank'"  class="col-8" style="font-size: 15px;color: white;padding: 5px 5px 5px 16px;">
                     IFSC
                </div>
                <div *ngIf="x?.paymenttype == 'bank'"  class="col-4" style="text-align: center;font-size: 15px;color: white;padding: 5px;">
                    {{x?.depositId.ifsc}}
                </div>
                <div class="col-8" style="font-size: 15px;color: white;padding: 5px 5px 5px 16px;">
                  Refrence No.
               </div>
               <div class="col-4" style="text-align: center;font-size: 15px;color: white;padding: 5px;">
                {{x.refrenceNo}}
            </div>
            <div class="col-8" style="font-size: 15px;color: white;padding: 5px 5px 5px 16px;">
                Amount
             </div>
             <div class="col-4" style="text-align: center;font-size: 15px;color: white;padding: 5px;">
              {{x.amount}}
          </div>
         
          <div class="col-8" style="font-size: 15px;color: white;padding: 5px 5px 5px 16px;">
            Status
         </div>
         <div class="col-4" style="text-align: center;font-size: 15px;color: white;padding: 5px;">
          {{x.status}}
      </div>
      <div class="col-12" style="margin: 48px 0px 28px 0px;" *ngIf="x.image.length >0">
        <img src="https://adminsocket.diamond222.com/uploads/screenshot/{{x?.image}}" alt="" style="width:99%;border-radius: 10px;">
    </div>
              </div>
              

        </div>
    </div>
    </div>