<section _ngcontent-serverapp-c186="" class="footer footer-login">
  <div _ngcontent-serverapp-c186="" class="footer-top">
    <div _ngcontent-serverapp-c186="" class="support-detail">
      <h2 _ngcontent-serverapp-c186="">24X7 Support</h2>
    </div>
    <div _ngcontent-serverapp-c186="" class="social-icons-box">
      <div _ngcontent-serverapp-c186="" class="social-icon"><a target="_blank"><img
            _ngcontent-serverapp-c186="" src="https://g1ver.sprintstaticdata.com/v35/static/front/img/social-icons/signal.png" alt="Signal"></a></div>
    </div>
  </div>
</section>
<div _ngcontent-serverapp-c187="" class="footer-bottom">
  <div _ngcontent-serverapp-c187="" class="secure-logo">
    <div _ngcontent-serverapp-c187=""><img _ngcontent-serverapp-c187="" src="../../assets/images/ssl.png"></div>
    <div _ngcontent-serverapp-c187="" class="ml-2"><b _ngcontent-serverapp-c187="" style="color: black;">100% SAFE</b>
      <div _ngcontent-serverapp-c187="">Protected connection and encrypted data.</div>
    </div>
  </div>
  <div _ngcontent-serverapp-c187="" class="d-inline-block"><a _ngcontent-serverapp-c187=""
      href="javascript:void(0)"><img _ngcontent-serverapp-c187="" src="../../assets/images/18plus.png"></a><a
      _ngcontent-serverapp-c187="" href="javascript:void(0)"><img _ngcontent-serverapp-c187=""
        src="../../assets/images/gamecare.png"></a><a _ngcontent-serverapp-c187="" href="javascript:void(0)"><img
        _ngcontent-serverapp-c187="" src="../../assets/images/gt.png"></a></div>
</div>
<div _ngcontent-serverapp-c187="" class="footer-text">
  <p _ngcontent-serverapp-c187="" class="text-center">© Copyright 2023. All Rights Reserved. Powered by {{ host }}.
  </p>
</div>